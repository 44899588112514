import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';
import SalesMarketReport from '../../SalesMarketReport/SalesMarketReport';

const SalesMarket = ({ onChartImagesChange }) => {
  const { propertyId } = useParams();

  const { data: property } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  const { area_code, property_type, number_of_bedrooms } = property?.property_index_data || {};

  if (!property) {
    return <></>;
  }

  return (
    <>
      <SalesMarketReport
        area_code={area_code}
        property_type={property_type}
        number_of_bedrooms={number_of_bedrooms}
        onChartImagesChange={onChartImagesChange}
      ></SalesMarketReport>
    </>
  );
};

export default SalesMarket;
