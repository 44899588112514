import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { useMemo } from 'react';
import DonutChart from 'jsx/components/Property/Components/DonutChart';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const IndustryCategoriesData = ({ district_code, area_code, categories, areaData }) => {
  const chartData = useMemo(
    () =>
      Object.entries(areaData).map(([key, value]) => ({
        name: key,
        value: Number(value.toFixed(2)),
      })),
    [areaData],
  );

  return (
    <>
      <div className="w-100">
        <div className="p-4">
          {areaData ? (
            <DonutChart
              chartData={chartData}
              type="Area"
              legendPosition="bottom"
              colors={['#edd078', '#78ed78', '#78cced', '#b378ed', '#ed7895']}
            />
          ) : (
            ''
          )}
        </div>
        <div className="flex p-4">
          <div>
            <table className="border-collapse ">
              <thead>
                <tr className="text-left">
                  <th className="font-medium p-2 text-md text-black">Range</th>
                  <th className="font-medium p-2 text-md text-black border-l">Area (%)</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((group, index) => (
                  <tr key={index}>
                    <td className="p-2">{group}</td>

                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#FF4560' }}>
                      {(areaData[group] * 100).toFixed(2)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustryCategoriesData;
