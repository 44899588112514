import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

const DonutChart = ({ chartData, type = 'District', legendPosition = 'right', colors = undefined }) => {
  const series = useMemo(() => chartData.map((item) => item.value), [chartData]);
  const labels = useMemo(() => chartData.map((item) => item.name), [chartData]);

  const getColors = () => {
    const baseColors =
      type === 'Area'
        ? ['#FF4560', '#FF6075', '#FF7A8A', '#FF96A5', '#FFB3C1']
        : ['#4A90E2', '#3282E5', '#1A73E8', '#5E9EF3', '#A3C8FF'];
    if (series.length === 3) {
      return [baseColors[0], baseColors[2], baseColors[4]];
    }
    return baseColors;
  };

  const options = useMemo(
    () => ({
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
        },
      },
      colors: !colors ? getColors() : colors,
      labels: labels,
      legend: {
        position: legendPosition,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [series, labels, type],
  );

  return (
    <div className="donut-chart w-full max-w-md">
      {series.length > 0 ? (
        <ReactApexChart options={options} series={series} type="donut" width="100%" />
      ) : (
        <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
      )}
    </div>
  );
};

export default DonutChart;
