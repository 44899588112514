import { ReactComponent as AnalyticsIcon } from 'icons/custom/analytics.svg';
import { ReactComponent as DeleteSVG } from 'icons/custom/delete.svg';
import { ReactComponent as HeartSolid } from 'icons/custom/heart-solid.svg';
import { ReactComponent as HeartRegular } from 'icons/custom/heart-regular.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';
import { Link } from 'react-router-dom';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import moment from 'moment';
import { propertySVGs } from '../../../../utils/helpers';
import { renderAccuracyInfo } from '../../Property/Helpers/Helpers';
import { formatAddress } from 'utils/address';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const getTableConfig = ({ toggleFavourite, handleClickDelete }) => {
  const columnsMetadata = {
    address: {
      Header: 'Address',
      id: 'address',

      accessor: (d) => {
        return (
          <Link className="flex items-center" to={`/property-analytics/${d?.id}`}>
            <div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{formatAddress(d?.address)} </div>
            {d?.parent_id ? (
              <div
                className="ml-1 rounded-md px-1 py-1"
                style={{
                  background: 'rgba(53, 113, 229, 0.15)',
                  color: '#3571E5',
                  fontSize: 10,
                  lineHeight: 1.2,
                }}
              >
                {d?.changed_number_of_bedrooms ? 'Cloned' : 'Revalued'}
              </div>
            ) : (
              ''
            )}
          </Link>
        );
      },
      headerStyle: {
        minWidth: '270px',
      },
      style: {
        color: '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '210px',
        paddingRight: '10px',
        cursor: 'pointer',
      },
    },
    created_at: {
      Header: 'Date added',
      headerStyle: {
        width: '120px',
        minWidth: '120px',
      },
      accessor: 'created_at',
      style: {
        width: '120px',
        minWidth: '120px',
      },
    },
    post_town: {
      Header: 'City',
      id: 'post_town',
      headerStyle: { width: '140px' },
      accessor: (d) => {
        return d?.property_index_data?.post_town ?? '-';
      },
    },
    portfolios: {
      Header: '',
      accessor: 'portfolios',
      headerStyle: { display: 'none' },
      style: {
        display: 'none',
      },
    },
    district: {
      Header: 'District',
      id: 'district_code',
      headerStyle: { width: '140px' },
      accessor: (d) => {
        return d?.property_index_data?.district_code ?? '-';
      },
    },
    property_type: {
      Header: 'Property Type',
      id: 'property_type',
      accessor: (d) => {
        return propertySVGs[d?.property_index_data?.property_type]?.name ?? '-';
      },
      headerStyle: { width: '130px' },
    },
    num_rooms: {
      Header: 'Number Of Bedrooms',
      id: 'number_of_bedrooms',
      accessor: (d) => {
        return d?.property_index_data?.number_of_bedrooms ?? '-';
      },
      headerStyle: { width: '130px' },
      style: {
        width: '90px',
      },
    },
    price: {
      Header: 'Our valuation estimate',
      headerStyle: { width: '140px' },
      id: 'price',
      accessor: 'price',

      renderer: (cell) => {
        return (
          <div className="flex justify-between">
            {cell?.value ? numeral(cell?.value).format() : '-'}
            {renderAccuracyInfo({ property: cell?.row?.original })}
          </div>
        );
      },

      style: {
        color: '#000000',
      },
    },
    last_transaction_date: {
      Header: 'Last Transaction Date',
      headerStyle: { width: '140px' },
      id: 'last_transaction_date',
      accessor: (d) => {
        return moment(d?.last_transaction_date || d?.property_index_data?.last_transaction_date).isBefore(moment())
          ? d?.last_transaction_date || d?.property_index_data?.last_transaction_date?.split(/-/)?.join('-')
          : '-';
      },
    },
    last_transaction_price: {
      Header: 'Last Transaction Price',
      headerStyle: { width: '140px' },
      id: 'last_transaction_price',
      accessor: (d) => {
        return (d?.last_transaction_price || d?.property_index_data?.last_transaction_price) > 0
          ? numeral(d?.last_transaction_price || d?.property_index_data?.last_transaction_price).format()
          : '-';
      },
      style: {
        color: '#000000',
      },
    },
    occupancy: {
      Header: 'Occupancy',
      headerStyle: {
        width: '140px',
      },
      style: {
        color: '#000000',
        width: '130px',
      },
      accessor: 'occupancy',
      formatter: (x) => {
        return x ? numeral(x / 100).format('%') : '-';
      },
    },
    property_images: {
      Header: '',
      headerStyle: {
        borderRight: 'none',
        width: '40px',
      },
      style: {
        color: '#000000',
        borderRight: 'none',
        width: '40px',
      },
      accessor: 'property_images',
      hideSort: true,
      formatter: (x) => {
        return (
          <>
            {x?.length ? (
              <InfoAsterisk
                text={`Property images available`}
                icon={<i className="fa fa-images cursor-pointer" aria-hidden="true" style={{ color: '#3571e5' }}></i>}
              />
            ) : (
              <i className="fa fa-images cursor-not-allowed text-gray-300" aria-hidden="true"></i>
            )}
          </>
        );
      },
    },
    floor_plans: {
      Header: '',
      headerStyle: {
        width: '40px',
      },
      style: {
        color: '#000000',
        width: '40px',
      },
      accessor: 'floor_plans',
      hideSort: true,
      formatter: (x) => {
        return (
          <>
            {x?.length ? (
              <InfoAsterisk
                text={`Property floor plans available`}
                icon={<i className="fa fa-home cursor-pointer" aria-hidden="true" style={{ color: '#3571e5' }}></i>}
              />
            ) : (
              <i className="fa fa-home cursor-not-allowed text-gray-300" aria-hidden="true"></i>
            )}
          </>
        );
      },
    },
    favourite: {
      Header: '',
      headerStyle: {
        borderRight: 'none',
        width: '40px',
      },
      style: {
        color: '#000000',
        borderRight: 'none',
        width: '40px',
      },
      accessor: 'favourite',
      hideSort: true,
      formatter: (x) => {
        return (
          <OverlayTrigger
            overlay={
              <Tooltip>
                <div className="text-center"> {x ? 'Remove from favourites' : 'Add to favourites'}</div>
              </Tooltip>
            }
          >
            {x ? <HeartSolid className="w-4 cursor-pointer" /> : <HeartRegular className="w-4 cursor-pointer" />}
          </OverlayTrigger>
        );
      },
      handleClick: (cell) => {
        toggleFavourite(cell.row.original);
      },
    },
    analytics: {
      Header: '',
      headerStyle: {
        borderRight: 'none',
        width: '40px',
      },
      style: {
        color: '#000000',
        borderRight: 'none',
        width: '40px',
      },
      id: 'analytics',
      hideSort: true,
      accessor: (d) => {
        return (
          <Link to={`/property-analytics/${d?.id}`}>
            <div className="flex">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <div className="text-center"> More information </div>
                  </Tooltip>
                }
              >
                <AnalyticsIcon className="cursor-pointer" />
              </OverlayTrigger>
            </div>
          </Link>
        );
      },
    },
    delete: {
      Header: '',
      headerStyle: {
        borderRight: 'none',
        width: '40px',
      },
      style: {
        color: '#000000',
        borderRight: 'none',
        width: '40px',
      },
      accessor: 'delete',
      hideSort: true,
      formatter: (x) => {
        return (
          <OverlayTrigger
            overlay={
              <Tooltip>
                <div className="text-center"> Delete Property </div>
              </Tooltip>
            }
          >
            <DeleteSVG className="cursor-pointer" />
          </OverlayTrigger>
        );
      },
      handleClick: (cell) => {
        handleClickDelete(cell.row.original.id);
      },
    },
  };
  return columnsMetadata;
};

export default getTableConfig;
