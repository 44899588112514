import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { flatCategories, houseCategories } from 'utils/constants';
import SalesMarketTablePdf from '../SalesMarketReport/SalesMarketTablePdf';
import RentsTablePdf from '../RentalMarketReport/RentalmarketTablesPdf/RentsTablePdf';
import DepthOfMarketPDF from '../RentalMarketReport/RentalmarketTablesPdf/DepthOfMarketPdf';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#edf2f6',
    padding: 20,
    fontSize: 11,
  },
  section: {
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 10,
    display: 'block',
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
  },
  infoBox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
  },
  label: {
    fontSize: 8,
  },
  colorBox: {
    width: 16,
    height: 8,
    marginLeft: 8,
  },
  pageHeader: {
    marginTop: 15,
    marginBottom: 15,
  },
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableFlexContainer: {
    alignItems: 'flex-start',
  },
  flexImgContent: {
    flex: 1,
    width: '30%',
    minWidth: '30%',
    maxWidth: '30%',
  },
  flexContent: {
    flex: 1,
    width: '50%',
    minWidth: '50%',
    maxWidth: '50%',
  },
  tableFlexContent: {
    flex: 1,
    width: '40%',
    minWidth: '40%',
    maxWidth: '40%',
  },
  chartFlexContent: {
    flex: 1,
    width: '50%',
    minWidth: '50%',
    maxWidth: '50%',
  },
  rentFlexContent: {
    flex: 1,
    width: '50%',
    minWidth: '50%',
    maxWidth: '50%',
  },
  addressRow: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  address: {
    fontSize: 16,
  },
  propertyDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  box: {
    backgroundColor: 'white',
    paddingHorizontal: 6,
    paddingVertical: 4,
    marginRight: 4,
    color: '#3571e5',
  },
  container: {
    marginBottom: 2,
  },
  header: {
    fontSize: 10,
    marginBottom: 6,
    color: '#747B8A',
  },
  content: {
    flexDirection: 'row',
    fontSize: 10,
    color: 'black',
    borderBottom: 1,
    borderBottomColor: '#F0F0F0',
    paddingBottom: 6,
    marginBottom: 9,
  },
  icon: {
    width: 14,
    height: 14,
    marginRight: 10,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    width: '33.3333%',
    paddingHorizontal: 4,
  },
  card: {
    marginBottom: 15,
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 12,
  },
  sectionTitle: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 10,
    display: 'block',
  },
  image: {
    height: 'auto',
    margin: 5,
  },
  propertyImage: {
    width: 170,
  },
  floorPlanImage: {
    width: 250,
  },
  imageRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  propertyImagesRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 10,
    width: '100%',
  },
  floorPlansRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: 10,
    width: '100%',
  },
});

const SalesMarketReportPdf = ({
  salesCombinedData,
  area_code,
  property_type,
  nu,
  number_of_bedrooms,
  salesByArea,
  chartImages,
}) => {
  return (
    <>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Sales Market Report </Text>
          <View style={styles.infoContainer}>
            <View style={styles.infoBox}>
              <Text style={styles.label}>Area ({area_code})</Text>
            </View>
          </View>
        </View>

        <View style={[styles.card, styles.flexContainer]}>
          <SalesMarketTablePdf
            style={styles.flexContent}
            data={salesCombinedData?.Flat}
            type="Flats"
            categories={flatCategories}
          />
          <SalesMarketTablePdf
            style={styles.flexContent}
            data={salesCombinedData?.House}
            type="Houses"
            categories={houseCategories}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Price Ranges</Text>
        </View>

        <View style={styles.card}>
          <View style={styles.flexContainer}>
            <View style={styles.rentFlexContent}>
              <RentsTablePdf data={salesCombinedData.Flat} type="Flats" categories={flatCategories} salesData={true} />
            </View>
            <View style={styles.rentFlexContent}>
              <RentsTablePdf
                data={salesCombinedData.House}
                type="Houses"
                categories={houseCategories}
                salesData={true}
              />
            </View>
          </View>

          {chartImages && (
            <View>
              <View style={styles.flexContainer}>
                <View style={styles.flexImgContent}>
                  <Text style={styles.sectionTitle}> Lower Quartile </Text>
                  <Image src={chartImages.salesChartLowerQuartileImg} />
                </View>
                <View style={styles.flexImgContent}>
                  <Text style={styles.sectionTitle}> Median Quartile </Text>
                  <Image src={chartImages.salesChartMedianQuartileImg} />
                </View>
                <View style={styles.flexImgContent}>
                  <Text style={styles.sectionTitle}> Upper Quartile </Text>
                  <Image src={chartImages.salesChartUpperQuartileImg} />
                </View>
              </View>
            </View>
          )}
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.section}>
            <Text style={styles.title}>Depth of the market</Text>
            <View style={styles.infoContainer}>
              <View style={styles.infoBox}>
                <Text style={styles.label}>Area ({area_code})</Text>
              </View>
            </View>
          </View>

          <View style={[styles.card, styles.flexContainer, styles.tableFlexContainer]}>
            <View style={styles.tableFlexContent}>
              <DepthOfMarketPDF
                property_type={property_type}
                number_of_bedrooms={number_of_bedrooms}
                district_code={''}
                area_code={area_code}
                district={undefined}
                area={salesByArea}
                salesData={true}
              />
            </View>

            {chartImages && (
              <View style={styles.chartFlexContent}>
                <Image src={chartImages?.salesDepthOfMarketChartImg} />
              </View>
            )}
          </View>
        </View>
      </Page>
    </>
  );
};

export default SalesMarketReportPdf;
