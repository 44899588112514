import { startCase } from 'lodash';

export const postcodeRegex = /[A-Za-z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}/;

export const formatPostTown = (input?: string | null): string | undefined | null => {
  return input
    ? input
        .toLowerCase()
        .split('-')
        .map((part) => startCase(part).replace(/On|In|By|Upon/g, (match) => match.toLowerCase()))
        .join('-')
    : input;
};

export const formatAddress = (input: string): string => {
  if (input.includes(', UK')) {
    return input.replace(', UK', '');
  }

  let address = input.toLowerCase();

  const postcodeMatch = address.match(postcodeRegex);

  let postcode = '';

  if (postcodeMatch) {
    postcode = postcodeMatch[0];
    address = address.replace(postcode, '').trim();
  }

  address = formatPostTown(address)!;

  const addressWords = address.split(' ');
  addressWords.forEach((word, index) => {
    if (word.length === 1) {
      addressWords[index] = word.toLowerCase();
    }
  });

  address = addressWords.join(' ');

  if (postcode) {
    const postcodeChars = postcode.split('');
    postcodeChars.splice(postcode.length - 3, 0, ' ');
    postcode = postcodeChars.join('');
    address = `${address} ${postcode.toUpperCase()}`;
  }

  return address;
};
