import Table from '../../Dashboard/Tables/BaseTable';
import debounce from 'lodash.debounce';
import axios from 'axios';

import Filter from './Filter';
import { useCallback, useEffect, useState } from 'react';
import { apiUpdateProperty, getPaginatedUserProperties } from '../../../../api/property';
import getTableConfig from '../Helpers/TableConfig';

function ListView({ handleClickDelete, portfolios, deletedPropertyIds, favouritesfilterActive, appraisal }) {
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [paginatedPropertiesList, setPaginatedPropertiesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const formattedPorfolioOptions = portfolios.map((portfolio) => {
    return { value: portfolio.id, label: portfolio.name };
  });
  formattedPorfolioOptions.unshift({ value: '', label: ' All ' });

  let cancelToken;
  const getPropertiesData = async (filter) => {
    setLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      const searchResponse = await getPaginatedUserProperties(
        {
          order_by: 'created_at',
          ascending: false,
          ...filter,
          status: ['Completed', 'Comparables In progress'],
          has_appraisal: appraisal ? true : undefined,
        },
        { cancelToken: cancelToken.token },
      );
      if (searchResponse && searchResponse?.data) {
        setPaginatedPropertiesList(searchResponse?.data);
        setPageCount(Math.ceil(searchResponse?.total_count / 10));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => getPropertiesData(params), 200),
    [],
  );

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;
      if (sortBy && sortBy.length) {
        formData.ascending = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }
      setPageIndex(pageIndex);
      setFilterData(formData);
      debouncedChangeHandler(formData);
    },
    [debouncedChangeHandler, filterData],
  );

  const updateFilter = useCallback(
    (filter) => {
      let formData = { ...filterData, ...filter };
      let resultFilter = {
        address: formData.address ? formData.address : undefined,
        post_towns: formData.post_town ? [formData?.post_town?.toUpperCase()] : [],
        rooms: formData.number_of_bedrooms ? [formData.number_of_bedrooms, formData.number_of_bedrooms + '.0'] : [],
        portfolio_ids: formData.portfolio ? [formData.portfolio] : [],
        property_types: formData.property_type ? [formData.property_type] : [],
        min_index_price: formData.min_index_price,
        max_index_price: formData.max_index_price,
        favourite: formData.favourite,
        limit: 10,
        offset: 0,
      };

      setFilterData(resultFilter);
      debouncedChangeHandler(resultFilter);
    },
    [debouncedChangeHandler, filterData],
  );

  const toggleFavourite = async (property) => {
    try {
      await apiUpdateProperty({ ...property, favourite: !property.favourite });
      debouncedChangeHandler(filterData);
    } catch (error) {
      throw new Error('Failed to update property');
    }
  };

  const columnsMetadata = getTableConfig({ toggleFavourite, handleClickDelete });

  useEffect(() => {
    if (deletedPropertyIds.length) {
      debouncedChangeHandler(filterData);
    }
  }, [deletedPropertyIds.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let formData = { ...filterData, favourite: favouritesfilterActive };
    setFilterData(formData);
    debouncedChangeHandler(formData);
  }, [favouritesfilterActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card">
      <Filter
        updateFilter={(params) => updateFilter(params)}
        portfolios={formattedPorfolioOptions}
        loading={loading}
      ></Filter>

      <Table
        columnsMetadata={columnsMetadata}
        sampleTableData={paginatedPropertiesList}
        filtersActive={false}
        paginationActive={true}
        staticData={false}
        title=""
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </div>
  );
}

export default ListView;
