import SecondaryButton from 'jsx/components/Dashboard/Buttons/SecondaryButton';
import { useState } from 'react';
import { propertySVGs } from 'utils/helpers';
import { ReactComponent as Info } from 'icons/custom/info-icon.svg';
import { ReactComponent as Refresh } from 'icons/custom/refresh.svg';
import { Link } from 'react-router-dom';
import PropertyPdfModal from './PropertyPdfModal';
import { formatAddress } from 'utils/address';

const AnalyticsHeader = ({ property, refreshProperty }) => {
  const [showPropertyPdfModal, setShowPropertyPdfModal] = useState(false);

  return (
    <>
      {showPropertyPdfModal ? (
        <PropertyPdfModal
          setShowModal={() => setShowPropertyPdfModal(false)}
          showModal={showPropertyPdfModal}
          property={property}
        />
      ) : (
        ''
      )}

      <div className="w-full flex items-center flex-col sm:flex-row justify-between mb-4">
        <div className="flex flex-wrap items-center justify-start">
          {property?.address && (
            <div className="text-black text-2xl font-medium mr-2">{formatAddress(property.address)}</div>
          )}
          {property?.property_index_data ? (
            <>
              <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                District: {property.property_index_data?.district_code}
              </div>
              <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                Type: {propertySVGs[property.property_index_data?.property_type]?.name}
              </div>
              <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                Rooms: {property.property_index_data?.number_of_bedrooms}
              </div>
            </>
          ) : (
            ''
          )}
        </div>

        <div className="flex">
          {property?.parent_id ? (
            <div
              style={{ backgroundColor: '#E1EAFB', fontSize: 12 }}
              className="px-2 py-2 rounded-lg flex items-center justify-start"
            >
              <div className="flex items-center justify-start">
                <Info className="w-5 mr-1" />
                This property has been {property?.changed_number_of_bedrooms ? 'cloned' : 'revalued'}
              </div>
              <Link
                className="underline pl-3"
                to={`/property-analytics/${property?.parent_id}`}
                style={{ color: '#3571E5' }}
              >
                See original property
              </Link>
            </div>
          ) : (
            ''
          )}

          <div>
            <SecondaryButton
              className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap"
              onClick={() => refreshProperty()}
            >
              <Refresh className="h-5 w-5 text-white mr-2" />
              Refresh Valuation
            </SecondaryButton>
          </div>

          <div>
            <div className="relative">
              <SecondaryButton
                className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap "
                disabled={showPropertyPdfModal}
                onClick={() => setShowPropertyPdfModal(true)}
              >
                Download PDF
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsHeader;
