import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';

import NearbyAmenities from 'jsx/components/Property/Components/NearbyAmenities';

const Amenities = () => {
  const { propertyId } = useParams();

  const { data: property } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  if (!property) {
    return <></>;
  }

  return <>{property?.latitude && property?.longitude && <NearbyAmenities property={property}></NearbyAmenities>}</>;
};

export default Amenities;
