import { columnsMetadataProperty } from '../../../../utils/alertsTableColumns';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import Table from '../../Dashboard/Tables/BaseTable';
import { useMemo } from 'react';
import { apiGetUserProperties } from '../../../../api/property';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import ErrorScreen from '../../SharedComponents/ErrorScreen';
import { formatAddress } from 'utils/address';

function PropertyAlerts({ targetTime }) {
  const {
    data: properties = [],
    isSuccess: propertiesLoaded,
    isLoading,
    isFetching,
    isError,
  } = useQuery(['properties'], ({ signal }) => apiGetUserProperties(signal));

  const propertiesData = useMemo(() => {
    if (propertiesLoaded) {
      const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
      const formattedPrevDate = moment(targetTime).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const formattedTwelfthDate = moment(targetTime).add(12, 'months').startOf('month').format('YYYY-MM-DD');
      const formattedThirteenthDate = moment(targetTime).add(13, 'months').startOf('month').format('YYYY-MM-DD');

      return properties.map((property) => {
        const rentPrice = property.rental_index_data?.forecast
          ? property.rental_index_data?.forecast[formattedCurrentDate]
          : 0;
        const prevRentPrice = property.rental_index_data?.forecast
          ? property.rental_index_data?.forecast[formattedPrevDate]
          : 0;
        const chgRentActual = prevRentPrice ? (rentPrice - prevRentPrice) / prevRentPrice : undefined;
        const prevRentPrice12th = property.rental_index_data?.forecast
          ? property.rental_index_data?.forecast[formattedTwelfthDate]
          : 0;
        const prevRentPrice13th = property.rental_index_data?.forecast
          ? property.rental_index_data?.forecast[formattedThirteenthDate]
          : 0;
        const chgRentForecast = prevRentPrice12th
          ? (prevRentPrice13th - prevRentPrice12th) / prevRentPrice12th
          : undefined;

        return {
          id: property.id,
          address: formatAddress(property.address),
          property_type: property.property_type,
          num_rooms: property.num_rooms,
          uprn: property.uprn,
          price: property.price,
          rentPrice: rentPrice,
          chgActual: parseFloat(property?.property_index_data?.chg_1m),
          chgForecast: !isNaN(parseFloat(property?.property_index_data?.est_chg_fy1y_1m))
            ? (parseFloat(property?.property_index_data?.est_chg_fy1y_1m) * 100).toFixed(2)
            : 0,
          prevRentPrice: prevRentPrice,
          prevRentPrice12th: prevRentPrice12th,
          prevRentPrice13th: prevRentPrice13th,
          chgRentActual: chgRentActual * 100,
          chgRentForecast: chgRentForecast * 100,
        };
      });
    } else {
      return [];
    }
  }, [properties, propertiesLoaded, targetTime]);
  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        {isError ? (
          <div className="card h-auto">
            <ErrorScreen />
          </div>
        ) : (
          <div className="card">
            <div className="card-header border-0">
              <h4 className="mb-0 fs-20 text-black">Property change notifications</h4>
            </div>
            {isLoading || isFetching ? (
              <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
            ) : (
              <Table
                columnsMetadata={columnsMetadataProperty}
                sampleTableData={propertiesData}
                filtersActive={true}
                paginationActive={false}
                staticData={true}
                stickyHeader={true}
                stripePattern={true}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyAlerts;
