import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';

import { useEffect, useState } from 'react';

import PropertyDemographics from 'jsx/components/Property/Components/PropertyDemographics';
import { getDemographicsByDistrictCode } from 'api/demographics';

const Demographics = () => {
  const { propertyId } = useParams();
  const [demographicsData, setDemographicsData] = useState(null);

  const { data: property } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  const getDemographicsByProperty = async (property) => {
    try {
      const demographicsData = await getDemographicsByDistrictCode(property?.property_index_data?.district_code);
      setDemographicsData({
        demographics: demographicsData,
        district_code: property?.property_index_data?.district_code,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (property?.property_index_data && property.status !== 'In progress') {
      getDemographicsByProperty(property);
    }
  }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!property) {
    return <></>;
  }

  return (
    <>
      <PropertyDemographics property={property} demographicsData={demographicsData} />
    </>
  );
};

export default Demographics;
