import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics, updatePropertyIndex } from 'api/property';
import Error404 from 'jsx/pages/Error404';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import { Switch, Route, NavLink, Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import queryClient from 'utils/queryClient';

import { generatePropertyComparables } from 'api/comparables';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import StatusModal from '../Dashboard/Modals/StatusModal';
import PropertyDashboard from './Components/PropertyDashboard';

import PropertyDetailsSection from './Routes/PropertyDetailsSection';
import PropertyPrice from './Routes/PropertyPrice';
import Demographics from './Routes/Demographics';
import Amenities from './Routes/Amenities';
import RentalMarket from './Routes/RentalMarket';
import PriceChange from './Routes/PriceChange';
import AnalyticsHeader from './Components/AnalyticsHeader';
import SalesMarket from './Routes/SalesMarket';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';

const PropertyAnalytics = () => {
  const { propertyId } = useParams();
  const { path, url } = useRouteMatch();

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === `/property-analytics/${history.location.pathname.split('/')[2]}`) {
      history.replace(`${url}/details`);
    }
  }, [history, url]);

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const {
    data: property,
    refetch: refetchProperty,
    error,
  } = useQuery(['property-analytics', propertyId], ({ signal }) => apiGetPropertyAnalytics(propertyId, signal), {
    refetchIntervalInBackground: true,
    refetchInterval: (data) => {
      if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
        queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
        return false;
      }
      return 30000;
    },
    onSuccess: () => {
      if (property?.status === 'Completed' && property?.property_index_data) {
        queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
      }
    },
  });

  const generateComparables = useCallback(async () => {
    let cancelTokenSource = axios.CancelToken.source();

    try {
      await generatePropertyComparables(
        { ...property, num_rooms: Number(property.num_rooms) },
        cancelTokenSource.token,
      );

      setStatusModalContent({
        show: true,
        type: 'success',
        description: `Comparables data for this property is being refreshed.
        This process usually takes 1-2 minutes but may sometimes take longer.`,
      });

      refetchProperty();
    } catch {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'An error occurred. Please try again later.',
      });
    }
  }, [property, refetchProperty]);

  const refreshProperty = useCallback(async () => {
    let cancelTokenSource = axios.CancelToken.source();

    const { uprn, num_rooms, original_uprn } = property;
    const propertyData = {
      ...property,
      uprn: original_uprn ? original_uprn : uprn,
      num_rooms: Number(num_rooms),
    };

    try {
      await updatePropertyIndex(propertyData, cancelTokenSource.token);

      setStatusModalContent({
        show: true,
        type: 'success',
        description: `Valuation for this property is being refreshed.
        This process usually takes 1-2 minutes but may sometimes take longer.`,
        action: (
          <Link to={'/properties/'}>
            <PrimaryButton>Back to Properties</PrimaryButton>
          </Link>
        ),
      });

      refetchProperty();
    } catch {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'An error occurred. Please try again later.',
      });
    }
  }, [property, refetchProperty]);

  if (error) {
    return <Error404 />;
  }

  if (property?.status === 'In progress') {
    return (
      <>
        <StatusModal
          setShowModal={() => setStatusModalContent({ show: false })}
          showModal={statusModalContent.show}
          content={statusModalContent}
        />

        <div
          className="w-full h-screen	m-auto flex flex-column justify-center items-center bg-white rounded-2xl"
          style={{ height: 'calc(100vh - 215px)' }}
        >
          <LoaderSvg className="animate-spin h-10 w-10 text-white" />
          <div className="text-3xl font-medium mt-14" style={{ color: '#3571E5' }}>
            Valuation in Progress
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />

      {property && <AnalyticsHeader property={property} refreshProperty={refreshProperty} />}

      <div className="flex flex-wrap gap-2 mb-8">
        <NavLink
          to={`${url}/details`}
          className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
          activeClassName="bg-blue-500 text-white"
        >
          Property Details
        </NavLink>

        <NavLink
          to={`${url}/charts`}
          className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
          activeClassName="bg-blue-500 text-white"
        >
          Price Charts
        </NavLink>
        <NavLink
          to={`${url}/demographics`}
          className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
          activeClassName="bg-blue-500 text-white"
        >
          Risk and Demographics
        </NavLink>
        {property?.latitude && property?.longitude && (
          <NavLink
            to={`${url}/amenities`}
            className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
            activeClassName="bg-blue-500 text-white"
          >
            Distance to Amenities
          </NavLink>
        )}
        <NavLink
          to={`${url}/sales-market`}
          className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
          activeClassName="bg-blue-500 text-white"
        >
          Sales Market Report
        </NavLink>
        <NavLink
          to={`${url}/rental-market`}
          className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
          activeClassName="bg-blue-500 text-white"
        >
          Rental Market Report
        </NavLink>
        {property?.latitude && property?.longitude && (
          <NavLink
            to={`${url}/price-change`}
            className=" h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 px-3 w-auto bg-blue-200 text-blue-700"
            activeClassName="bg-blue-500 text-white"
          >
            Price Change Map
          </NavLink>
        )}
      </div>

      {property && <PropertyDashboard property={property} generateComparables={() => generateComparables()} />}

      <Switch>
        <Route path={`${path}/details`} component={PropertyDetailsSection} />
        <Route path={`${path}/charts`} component={PropertyPrice} />
        <Route path={`${path}/demographics`} component={Demographics} />
        <Route path={`${path}/amenities`} component={Amenities} />
        <Route path={`${path}/sales-market`} component={SalesMarket} />
        <Route path={`${path}/rental-market`} component={RentalMarket} />
        <Route path={`${path}/price-change`} component={PriceChange} />
      </Switch>
    </>
  );
};

export default PropertyAnalytics;
