import { useState } from 'react';
import Select from 'react-select';

import { ReactComponent as DownloadSvg } from 'icons/custom/download.svg';
import { ReactComponent as FilterSvg } from 'icons/custom/filter.svg';

import ItemVsIndexChart from '../Dashboard/Charts/ItemVsIndexChart';
import DetailedSearchModal from '../Dashboard/Modals/DetailedSearchModal';
import SearchDataTable from './Components/SearchDataTable';
import RentalCharts from './Components/RentalCharts';
import PageHead from '../additionalModules/PageHead';
import SecondaryButton from '../Dashboard/Buttons/SecondaryButton';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';
import { seriesObjectToArray } from '../../../utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { getTargetTime } from '../../../api/propertyIndex';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import Listings from './Components/Listings';
import EpcData from './Components/EpcData';
import RentalMarketReportWrapper from './Components/RentalMarketReportWrapper';
import SalesMarketReport from '../SalesMarketReport/SalesMarketReport';

const LocationSearch = () => {
  const [searchTableData, setSearchTableData] = useState([]);
  const [selectedIndex1, setSelectedIndex1] = useState({});
  const [selectedIndex2, setSelectedIndex2] = useState({});
  const [csvEncodedURI, updateCSVEncodedURI] = useState('');
  const [benchmarkDropdown1, setBenchmarkDropDown1] = useState([]);
  const [benchmarkDropdown2, setBenchmarkDropDown2] = useState([]);
  const [detailedSearchModal, setDetailedSearchModal] = useState(false);

  const [propertyData, setPropertyData] = useState({
    district_code: '',
    area_code: '',
    propertyType: '',
    numberOfBedrooms: '',
  });

  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));

  const handleChange1 = function (selectedOption) {
    setSelectedIndex1(selectedOption);

    const { district, property_type, number_of_bedrooms } = selectedOption || {};
    const area_code = district?.match(/[A-Za-z]+/g)?.[0] || '';

    setPropertyData({
      district_code: district,
      area_code,
      property_type,
      number_of_bedrooms,
    });
  };

  const handleChange2 = function (selectedOption) {
    setSelectedIndex2(selectedOption);
  };

  function getDropdownData(data, selectedFromDropdown = false) {
    var dropdownData = data;
    dropdownData = dropdownData.map((eachValue) => ({
      label: `${eachValue.post_town}, District: ${eachValue.district}, Type: ${eachValue.property_type}, Rooms: ${eachValue.number_of_bedrooms}`,
      value: eachValue.super_index_name,
      id: eachValue.index,
      forecast: eachValue.forecast,

      post_town: eachValue.post_town,
      district: eachValue.district,
      property_type: eachValue.property_type,
      number_of_bedrooms: eachValue.number_of_bedrooms,

      rental_index_forecast:
        eachValue?.rental_index && eachValue?.rental_index?.length ? eachValue?.rental_index[0]?.forecast : {},
    }));

    const selectedOption = dropdownData[0];
    const secondOption = dropdownData[1];
    dropdownData.sort((a, b) => a.label.localeCompare(b.label));

    if (!selectedFromDropdown) {
      setBenchmarkDropDown1(dropdownData);
      setBenchmarkDropDown2(dropdownData);
      handleChange1(selectedOption);
      handleChange2(secondOption);
    } else {
      setBenchmarkDropDown2(dropdownData);
      handleChange2(selectedOption);
    }
  }

  const downloadCSV = () => {
    window.open(csvEncodedURI);
  };

  const handleUpdateDropdown = (data, resetData = false) => {
    if (!resetData) {
      getDropdownData(data, true);
    } else {
      getDropdownData(searchTableData, false);
    }
  };

  const updateSearchTableData = (data) => {
    setSearchTableData(data);
    getDropdownData(data);
  };

  return (
    <>
      <DetailedSearchModal
        setShowModal={setDetailedSearchModal}
        showModal={detailedSearchModal}
        handleSuccess={handleUpdateDropdown}
      />

      <PageHead activePage="Investment Search AI" pageName={'Investment Search AI'} />

      <SearchDataTable updateSearchTableData={(data) => updateSearchTableData(data)} />

      <EpcData />

      <Listings dropdownOptions={benchmarkDropdown1} selectedIndex={selectedIndex1} />

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="pb-0 m-3 flex justify-start items-center">
              <h4 className="fs-16 mb-0 mx-3 my-0 py-0"> Price Index: </h4>
              <Select
                className="w-96"
                key="lefthdropdown"
                value={selectedIndex1}
                onChange={handleChange1}
                options={benchmarkDropdown1}
                isSearchable={true}
              />
              <div className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                vs
              </div>
              <Select
                className="mr-2 w-96"
                key="rightdropdown"
                value={selectedIndex2}
                onChange={handleChange2}
                options={benchmarkDropdown2}
                isSearchable={true}
              />
              <PrimaryButton className="mx-1.5" onClick={() => setDetailedSearchModal(true)}>
                <FilterSvg className="mr-1.5" />
                Search Filters
              </PrimaryButton>
              <SecondaryButton className="ml-auto w-36" onClick={downloadCSV}>
                <DownloadSvg className="mr-1.5" />
                Download CSV
              </SecondaryButton>
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {selectedIndex1?.forecast ? (
                  <ItemVsIndexChart
                    updateCSVEncodedURI={updateCSVEncodedURI}
                    address={selectedIndex1?.label}
                    index={selectedIndex2?.label}
                    itemGraphSeriesData={seriesObjectToArray(selectedIndex1?.forecast, targetTime)}
                    benchmarkGraphSeriesData={seriesObjectToArray(selectedIndex2?.forecast, targetTime)}
                    secondaryAxisButtonEnabled={true}
                  />
                ) : (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {propertyData.area_code ? (
        <SalesMarketReport
          area_code={propertyData.area_code}
          property_type={propertyData.property_type}
          number_of_bedrooms={propertyData.number_of_bedrooms}
        ></SalesMarketReport>
      ) : null}

      {propertyData.district_code ? <RentalMarketReportWrapper {...propertyData} /> : null}

      <RentalCharts selectedIndex1={selectedIndex1} selectedIndex2={selectedIndex2} targetTime={targetTime} />
    </>
  );
};
export default LocationSearch;
