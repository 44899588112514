import React, { useMemo, useState } from 'react';

import PropertyParameter from './PropertyParameter';
import { ReactComponent as LocationSVG } from 'icons/custom/location.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

import PropertyTransactions from './PropertyTransactions';
import TransactionsModal from '../Modals/TransactionsModal';
import { getPropertyTransactions } from '../../../../api/comparables';
import { useQuery } from '@tanstack/react-query';
import { getPropertyParameters } from '../Helpers/Helpers';
import { formatAddress } from 'utils/address';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PropertyDetails = ({ property }) => {
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);

  const propertyParameters = getPropertyParameters(property);

  const { data: transactionsData, isSuccess: transactionsLoaded } = useQuery(
    ['property-transactions', property.id, 'soldPrice'],
    ({ signal }) =>
      getPropertyTransactions(
        { uprn: property.original_uprn ? property.original_uprn : property.uprn, type: 'soldPrice' },
        signal,
      ),
  );

  const { data: listingsData, isSuccess: listingsLoaded } = useQuery(
    ['property-transactions', property.id, 'askingPrice'],
    ({ signal }) =>
      getPropertyTransactions(
        { uprn: property.original_uprn ? property.original_uprn : property.uprn, type: 'askingPrice' },
        signal,
      ),
  );

  const formattedTransactions = useMemo(() => {
    if (transactionsLoaded && listingsLoaded) {
      const listingsWithType = listingsData.data.map((item) => ({
        ...item,
        type: 'listing',
        sortingDate: item.date_appeared,
      }));

      const transactionsWithType = transactionsData.data.map((item) => ({
        ...item,
        type: 'transaction',
        sortingDate: item.date_sold,
      }));

      const sortedData = [...listingsWithType, ...transactionsWithType].sort((a, b) => {
        return new Date(b.sortingDate) - new Date(a.sortingDate);
      });

      for (let i = sortedData.length - 1; i > 0; i--) {
        const currentTransaction = sortedData[i];
        const previousTransaction = sortedData[i - 1];

        if (currentTransaction.bedrooms !== previousTransaction.bedrooms) {
          previousTransaction.modifiedRooms = true;
        }
      }

      return sortedData;
    } else {
      return [];
    }
  }, [transactionsLoaded, listingsLoaded, listingsData, transactionsData]);

  return (
    <>
      <TransactionsModal
        property={property}
        transactions={formattedTransactions}
        setShowModal={setShowTransactionsModal}
        showModal={showTransactionsModal}
      />

      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Property details </h4>
      <div className="card p-4">
        <div className="row h-full overflow-hidden">
          <div className="col-5"></div>
          <div className="col-12">
            <div className="flex flex-row">
              <div className="mr-3 w-4">
                <LocationSVG />
              </div>
              <div className="font-medium	text-2xl text-black pb-6">{formatAddress(property?.address)}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-8">
                <div className="row">
                  {propertyParameters.map((parameter, index) => (
                    <div key={index} className="col-lg-6 col-xl-4">
                      <PropertyParameter parameter={parameter} />
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-6 col-xl-4 ">
                {formattedTransactions.length ? (
                  <div>
                    <PropertyTransactions
                      property={property}
                      transactions={formattedTransactions}
                      showModal={() => setShowTransactionsModal(true)}
                      modalView={false}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PropertyDetails);
