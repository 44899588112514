import React, { useEffect, useState } from 'react';
import { formatRentalYieldData, seriesObjectToArray } from '../../../../utils/helpers';
import { apiGetRentalIndexByParams } from '../../../../api/rentalIndex';
import ItemVsIndexChart from '../../Dashboard/Charts/ItemVsIndexChart';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import { apiUpdateProperty } from '../../../../api/property';
import OverrideRentsModal from './OverrideRentsModal';
import emptyImg from 'icons/custom/emptyChart.png';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyRentalIndex } from '../../../../api/propertyRentalIndex';
import { formatAddress } from 'utils/address';

const RentalCharts = ({
  property,
  selectedBenchmark,
  targetTime,
  updateProperty,
  setRentPriceChartImg,
  setRentYieldChartImg,
}) => {
  const address = `${property.address},
                    District: ${property.property_index_data?.district_code},
                    Type: ${property.property_index_data?.property_type},
                    Rooms: ${property.property_index_data?.number_of_bedrooms}`;

  const [rentalBenchmarkGraphSeriesData, setRentalBenchmarkGraphSeriesData] = useState([]);
  const [rentalYieldBenchmarkGraphSeriesData, setRentalYieldBenchmarkGraphSeriesData] = useState([]);
  const [rentalBenchmark, setRentalBenchmark] = useState(undefined);

  const [rentalIndexGraphSeriesData, setRentalIndexGraphSeriesData] = useState([]);
  const [rentalYieldGraphSeriesData, setRentalYieldGraphSeriesData] = useState([]);

  const [showOverrideRentsModal, setShowOverrideRentsModal] = useState(false);
  const [overriddenRentsData, setOverriddenRentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const { data: propertyRentalData } = useQuery(['propertyRentalData', property.id], ({ signal }) =>
    apiGetPropertyRentalIndex(property.uprn, signal),
  );

  const getRentalBenchmarkDropdownData = async (selectedBenchmark, property) => {
    try {
      if (selectedBenchmark?.district && selectedBenchmark?.property_type && selectedBenchmark?.number_of_bedrooms) {
        setRentalBenchmark(
          (property.property_rental_index_data
            ? 'District: ' + selectedBenchmark.district
            : 'Area: ' + selectedBenchmark.area_code) +
            ', Property Type: ' +
            selectedBenchmark.property_type +
            ', Number Of Bedrooms: ' +
            selectedBenchmark.number_of_bedrooms,
        );
        const responseData = await apiGetRentalIndexByParams({
          districtCode: property.property_rental_index_data ? selectedBenchmark.district : selectedBenchmark.area_code,
          propertyType: selectedBenchmark.property_type,
          numBedrooms: selectedBenchmark.number_of_bedrooms,
        });

        if (responseData && responseData.forecast) {
          setRentalBenchmarkGraphSeriesData(seriesObjectToArray(responseData.forecast, targetTime));
          setRentalYieldBenchmarkGraphSeriesData(
            formatRentalYieldData(responseData.forecast, selectedBenchmark.forecast, targetTime),
          );
        }
      } else {
        setRentalBenchmark(undefined);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRentalIndexData = async (property, propertyRentalData) => {
    try {
      let rentalSeriesData = [];
      let rentalForecastData = {};
      if (propertyRentalData?.data?.length && propertyRentalData.data[0]) {
        rentalForecastData = propertyRentalData.data[0]?.ForecastJSON;
      } else {
        rentalForecastData = property.rental_index_data.forecast || {};
      }

      rentalSeriesData = seriesObjectToArray(rentalForecastData, targetTime);

      setRentalYieldGraphSeriesData(
        formatRentalYieldData(rentalForecastData, property.property_index_data?.forecast, targetTime),
      );

      if (property.override_rents) {
        setOverriddenRentsData(seriesObjectToArray(property.override_rents || {}, targetTime));
      } else {
        setOverriddenRentsData(rentalSeriesData);
      }

      setRentalIndexGraphSeriesData(rentalSeriesData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleOverrideRentsSuccess = async (data) => {
    try {
      const updatePropertyResponse = await apiUpdateProperty({
        ...property,
        num_rooms: parseInt(property.num_rooms),
        override_rents: data,
      });
      if (updatePropertyResponse.data) {
        updateProperty({ override_rents: updatePropertyResponse.data[0].override_rents });
        setStatusModalContent({
          show: true,
          type: 'success',
          description: 'Property successfully updated',
        });
      }
    } catch (error) {
      console.error(error);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  useEffect(() => {
    getRentalBenchmarkDropdownData(selectedBenchmark, property);
  }, [selectedBenchmark, property]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (property?.property_index_data) {
      getRentalIndexData(property, propertyRentalData);
    }
  }, [property, propertyRentalData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <OverrideRentsModal
        showModal={showOverrideRentsModal}
        setShowModal={setShowOverrideRentsModal}
        handleSuccess={handleOverrideRentsSuccess}
        overriddenData={overriddenRentsData}
      ></OverrideRentsModal>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Property rent price chart </h4>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate"> Rental Index: {formatAddress(address)} </h4>
              {rentalBenchmark && (
                <>
                  <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                    vs
                  </span>
                  <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate">{rentalBenchmark}</h4>
                </>
              )}
            </div>
            <div className="mb-3 px-8 flex">
              <PrimaryButton
                onClick={() => setShowOverrideRentsModal(true)}
                disabled={!rentalIndexGraphSeriesData.length}
              >
                Override Rents
              </PrimaryButton>
            </div>

            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {!loading && (rentalIndexGraphSeriesData.length || rentalBenchmarkGraphSeriesData.length) ? (
                  <ItemVsIndexChart
                    itemGraphSeriesData={rentalIndexGraphSeriesData}
                    address={address}
                    index={rentalBenchmark}
                    benchmarkGraphSeriesData={rentalBenchmark ? rentalBenchmarkGraphSeriesData : []}
                    secondaryAxisButtonEnabled={!!rentalBenchmark}
                    index2={property?.override_rents ? 'Overridden Rents' : ''}
                    benchmark2GraphSeriesData={property?.override_rents ? overriddenRentsData : []}
                    renderImage={setRentPriceChartImg}
                  />
                ) : loading ? (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                ) : (
                  <div className="items-center flex flex-column justify-center my-5">
                    <img className="h-44" src={emptyImg} alt="error" />
                    <div className="text-lg text-black font-semibold text-center my-2"> No Data </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate"> Rental Yield: {formatAddress(address)} </h4>
              {rentalBenchmark && (
                <>
                  <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                    vs
                  </span>
                  <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate">{rentalBenchmark}</h4>
                </>
              )}
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {loading ? (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                ) : rentalYieldGraphSeriesData.length || rentalYieldBenchmarkGraphSeriesData.length ? (
                  <ItemVsIndexChart
                    itemGraphSeriesData={rentalYieldGraphSeriesData}
                    address={address}
                    index={rentalBenchmark}
                    benchmarkGraphSeriesData={rentalYieldBenchmarkGraphSeriesData}
                    dataType={'rate'}
                    secondaryAxisButtonEnabled={true}
                    index2={property?.override_rents ? 'Overridden Rents' : ''}
                    benchmark2GraphSeriesData={
                      property?.override_rents
                        ? formatRentalYieldData(
                            property?.override_rents,
                            property?.property_index_data?.forecast,
                            targetTime,
                          )
                        : []
                    }
                    renderImage={setRentYieldChartImg}
                  />
                ) : (
                  <div className="items-center flex flex-column justify-center my-5">
                    <img className="h-44" src={emptyImg} alt="error" />
                    <div className="text-lg text-black font-semibold text-center my-2"> No Data </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalCharts;
