import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { generateStaticMapURL, propertySVGs } from '../../../../utils/helpers';
import { getPropertyParameters, getPropertyDemographicsParameters } from '../Helpers/Helpers';
import PdfDashboardSection from './PdfDashboardSection';
import { ReactComponent as RealyseLogo } from '../../../../icons/custom/logo.svg';
import ReactPdfSvg from './ReactPdfSvg';
import PdfComparablesSection from './PdfComparablesSection';
import PdfListingsSection from './PdfListingsSection';
import PdfBenchmarkTable from './PdfBenchmarkTable';
import PdfOptimalComparablesSection from './PdfOptimalComparablesSection';
import RentalMarketReportPdf from 'jsx/components/RentalMarketReport/RentalMarketReportPdf';
import PdfPropertyTransactions from './PdfPropertyTransactions';
import SalesMarketReportPdf from 'jsx/components/SalesMarketReport/SalesMarketReportPdf';
import { formatAddress } from 'utils/address';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#edf2f6',
    padding: 20,
    fontSize: 11,
  },
  pageHeader: {
    marginTop: 15,
    marginBottom: 15,
  },
  addressRow: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  address: {
    fontSize: 16,
  },
  propertyDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  box: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 4,
    color: '#7B8FB7',
  },
  container: {
    marginBottom: 2,
  },
  header: {
    fontSize: 10,
    marginBottom: 6,
    color: '#747B8A',
  },
  content: {
    flexDirection: 'row',
    fontSize: 10,
    color: 'black',
    borderBottom: 1,
    borderBottomColor: '#F0F0F0',
    paddingBottom: 6,
    marginBottom: 9,
  },
  icon: {
    width: 14,
    height: 14,
    marginRight: 10,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    width: '33.3333%',
    paddingHorizontal: 4,
  },
  card: {
    marginBottom: 15,
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 12,
  },
  sectionTitle: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 10,
    display: 'block',
  },
  image: {
    height: 'auto',
    margin: 5,
  },
  propertyImage: {
    width: 170,
  },
  floorPlanImage: {
    width: 250,
  },
  imageRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  propertyImagesRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 10,
    width: '100%',
  },
  floorPlansRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: 10,
    width: '100%',
  },
});

const renderSinglePage = (images, maxImages, title, imagesPerRowStyle, imageStyle) => {
  const limitedImages = images.slice(0, maxImages);

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <View style={imagesPerRowStyle}>
        {limitedImages.map((imageUrl, index) => (
          <Image key={index} src={`${imageUrl}?i`} style={[styles.image, imageStyle]} />
        ))}
      </View>
    </Page>
  );
};

const ParameterItem = ({ parameter }) => (
  <View style={styles.container}>
    <Text style={styles.header}>{parameter.name}</Text>
    <View style={styles.content}>
      <View style={styles.icon}>
        <ReactPdfSvg scale={0.8}>{parameter.icon}</ReactPdfSvg>
      </View>
      {parameter.value ? <Text>{parameter.value}</Text> : <Text> - </Text>}
    </View>
  </View>
);

const PropertyPdf = ({
  property,
  priceChartImg,
  premiumValue,
  rentPriceChartImg,
  rentYieldChartImg,
  demographicsData,
  comparablesData,
  optimalComparablesData,
  listingsData,
  searchTableData,
  chartImages,
  district_code,
  area_code,
  property_type,
  number_of_bedrooms,
  employmentCategories,
  employmentDistrictData,
  employmentAreaData,
  householdCategories,
  householdDistrictData,
  householdAreaData,
  tenureCategories,
  tenureDistrictData,
  tenureAreaData,
  rentalMarketDistrict,
  rentalMarketArea,
  combinedData,
  transactions,
  salesChartImages,
  salesByArea,
  salesCombinedData,
}) => {
  const propertyParameters = getPropertyParameters(property);
  const propertyDemographicsParameters = getPropertyDemographicsParameters(demographicsData);
  const comparablesMapImageUrl =
    comparablesData && comparablesData.data?.length ? generateStaticMapURL(comparablesData?.data) : '';
  const listingsMapImageUrl = listingsData && listingsData.data?.length ? generateStaticMapURL(listingsData?.data) : '';

  const propertyImages = property.property_images || [];
  const floorPlans = property.floor_plans || [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <ReactPdfSvg>
          <RealyseLogo />
        </ReactPdfSvg>
        <View style={styles.pageHeader}>
          <View style={styles.addressRow}>
            <Text style={styles.address}> {formatAddress(property?.address)} </Text>
          </View>
          <View style={styles.propertyDetailsRow}>
            <View style={styles.box}>
              <Text>District: {property.property_index_data.district_code}</Text>
            </View>
            <View style={styles.box}>
              <Text>Type: {propertySVGs[property.property_index_data?.property_type]?.name}</Text>
            </View>
            <View style={styles.box}>
              <Text>Rooms: {property.property_index_data.number_of_bedrooms}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.sectionTitle}>Overview</Text>
        <View style={styles.card}>
          <PdfDashboardSection property={property} premiumValue={premiumValue} />
        </View>
        <Text style={styles.sectionTitle}> Property Details </Text>
        <View style={styles.card}>
          <View style={styles.row}>
            {propertyParameters.map((parameter, index) => (
              <View key={index} style={styles.column}>
                <ParameterItem parameter={parameter} />
              </View>
            ))}
            {propertyDemographicsParameters.map((parameter, index) => (
              <View key={index} style={styles.column}>
                <ParameterItem parameter={parameter} />
              </View>
            ))}
          </View>
        </View>
      </Page>

      {propertyImages.length > 0 &&
        renderSinglePage(propertyImages, 15, 'Property Images', styles.propertyImagesRow, styles.propertyImage)}
      {floorPlans.length > 0 &&
        renderSinglePage(floorPlans, 4, 'Floor Plans', styles.floorPlansRow, styles.floorPlanImage)}

      <Page size="A4" style={styles.page}>
        {priceChartImg && (
          <View>
            <Text style={styles.sectionTitle}> Property Price Chart </Text>
            <View style={styles.card}>
              <Image src={priceChartImg} />
            </View>
          </View>
        )}
        {rentPriceChartImg && (
          <View>
            <Text style={styles.sectionTitle}> Property Rent Price Chart </Text>
            <View style={styles.card}>
              <Image src={rentPriceChartImg} />
            </View>
          </View>
        )}
        {rentYieldChartImg && (
          <View>
            <Text style={styles.sectionTitle}> Property Rental Yield Chart </Text>
            <View style={styles.card}>
              <Image src={rentYieldChartImg} />
            </View>
          </View>
        )}
      </Page>

      <RentalMarketReportPdf
        chartImages={chartImages}
        combinedData={combinedData}
        district_code={district_code}
        property_type={property_type}
        number_of_bedrooms={number_of_bedrooms}
        area_code={area_code}
        employmentCategories={employmentCategories}
        employmentDistrictData={employmentDistrictData}
        employmentAreaData={employmentAreaData}
        householdCategories={householdCategories}
        householdDistrictData={householdDistrictData}
        householdAreaData={householdAreaData}
        tenureCategories={tenureCategories}
        tenureDistrictData={tenureDistrictData}
        tenureAreaData={tenureAreaData}
        depthOfMarketDistrict={rentalMarketDistrict}
        depthOfMarketArea={rentalMarketArea}
      />

      <SalesMarketReportPdf
        salesCombinedData={salesCombinedData}
        area_code={area_code}
        property_type={property_type}
        number_of_bedrooms={number_of_bedrooms}
        salesByArea={salesByArea}
        chartImages={salesChartImages}
      />

      {comparablesData && comparablesData.data?.length ? (
        <Page size="A4" style={styles.page}>
          <Text style={styles.sectionTitle}> Comparables </Text>
          <View style={styles.card}>
            <Image src={comparablesMapImageUrl} />
          </View>
          <View style={styles.card}>
            <PdfComparablesSection comparablesData={comparablesData}></PdfComparablesSection>
          </View>
        </Page>
      ) : (
        ''
      )}

      {optimalComparablesData && optimalComparablesData?.length ? (
        <Page size="A4" style={styles.page}>
          <Text style={styles.sectionTitle}> Favourite Comparables </Text>
          <View style={styles.card}>
            <PdfOptimalComparablesSection
              optimalComparablesData={optimalComparablesData}
            ></PdfOptimalComparablesSection>
          </View>
        </Page>
      ) : (
        ''
      )}

      {listingsData && listingsData.data?.length ? (
        <Page size="A4" style={styles.page}>
          <Text style={styles.sectionTitle}> Listings </Text>
          <View style={styles.card}>
            <Image src={listingsMapImageUrl} />
          </View>
          <View style={styles.card}>
            <PdfListingsSection listingsData={listingsData}></PdfListingsSection>
          </View>
        </Page>
      ) : (
        ''
      )}

      <Page size="A4" style={styles.page}>
        <PdfPropertyTransactions property={property} transactions={transactions}></PdfPropertyTransactions>
      </Page>

      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>
          Expected Returns for {property.property_index_data ? property.property_index_data?.district_code : ''}{' '}
          Properties
        </Text>
        <View style={styles.card}>
          <PdfBenchmarkTable searchTableData={searchTableData}></PdfBenchmarkTable>
        </View>
      </Page>
    </Document>
  );
};

export default PropertyPdf;
