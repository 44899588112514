import React, { useState } from 'react';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as CloneIcon } from 'icons/custom/clone.svg';
import { ReactComponent as ComparablesIcon } from 'icons/custom/comparables.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import SecondaryButton from 'jsx/components/Dashboard/Buttons/SecondaryButton';

import firstCtaBg from '../../../../assets/cta-bg-1.png';
import secondCtaBg from '../../../../assets/cta-bg-2.png';

import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiGetPremiumValue } from 'api/premiumvalue';
import { getPropertyComparables } from 'api/comparables';
import ClonePropertyModal from 'jsx/components/Property/Modals/ClonePropertyModal';
import PrimaryButton from 'jsx/components/Dashboard/Buttons/PrimaryButton';
import StatusModal from 'jsx/components/Dashboard/Modals/StatusModal';
import { getPropertyDashboardParams } from 'jsx/components/Property/Helpers/Helpers';
import { getCoordinates } from 'api/search';
import { createProperty } from 'api/property';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PropertyDashboard = ({
  property,

  generateComparables,
}) => {
  const { data: premiumValue, isLoading: premiumValueLoading } = useQuery({
    queryKey: [
      'premiumValue',
      `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
    ],
    queryFn: ({ signal }) =>
      apiGetPremiumValue(
        `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
        signal,
      ),
    enabled: !!property?.property_index_data,
  });

  const { data: comparablesData, isLoading: comparablesLoading } = useQuery(
    ['comparables', property?.id, property?.status],
    ({ signal }) =>
      getPropertyComparables(
        property?.id,
        {
          max_price: property?.price ? Math.round(property?.price * 1.3) : 10000000,
          min_price: property?.price ? Math.round(property?.price * 0.7) : 0,
          max_size: null,
          null_toggle: true,
          offset: 0,
          old_new_lr: null,
          filtered_ids: [],
          bathrooms: [],
          current_energy_rating_epc: [],
          distance_from: null,
          freehold_leasehold: null,
          limit: 10,
          min_size: null,
          order_by: 'date',
          ascending: false,
        },
        signal,
      ),
    {
      enabled: !!property?.property_index_data && property?.status === 'Completed',
    },
  );

  const [clonePropertyModal, setClonePropertyModal] = useState(false);

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const propertyDashboardParams = getPropertyDashboardParams(property, premiumValue, premiumValueLoading);

  const queryClient = useQueryClient();

  const getPropertyCoordinates = async (address) => {
    try {
      const response = await getCoordinates({ query: address });
      const coordinates = {};
      if (response?.data?.data) {
        const [latitude, longitude] = response?.data?.data?.point.split(',');
        coordinates.latitude = Number(latitude);
        coordinates.longitude = Number(longitude);
      }
      return coordinates;
    } catch (error) {}
  };

  const cloneProperty = async (updatedParameters) => {
    try {
      const propertyParams = {
        uprn: property?.uprn,
        address: property?.address,
        property_type: property?.property_index_data?.property_type,
        num_rooms: Number(property?.property_index_data?.number_of_bedrooms),
        number_of_bedrooms: Number(property?.property_index_data?.number_of_bedrooms),
        area_code: property?.property_index_data?.area_code,
        district_code: property?.property_index_data?.district_code,
        post_code: property?.address?.split(' ').pop(),
        post_town: property?.property_index_data?.post_town,
        custom: true,
        latitude: property?.latitude,
        longitude: property?.longitude,
        epc: property?.epc,
        parent_id: property?.id,
        ...updatedParameters,
      };

      if (propertyParams.uprn.charAt(0) === '-' && !(propertyParams.latitude && propertyParams.longitude)) {
        const coordinates = await getPropertyCoordinates(propertyParams.address);
        Object.assign(propertyParams, coordinates);
      }
      const newPropertyResponse = await createProperty(propertyParams);
      if (newPropertyResponse) {
        queryClient.invalidateQueries(['rawProperties']);
        queryClient.invalidateQueries(['rawProperties', 'in-progress']);
        setStatusModalContent({
          show: true,
          type: 'success',
          description: `Valuation data for this property is now being generated.
          You'll receive an email notification when it's available.
          This process normally takes 1-2 minutes but may sometimes take a little longer.`,
          action: (
            <Link to={'/properties/'}>
              <PrimaryButton>View Properties</PrimaryButton>
            </Link>
          ),
        });
      }
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <ClonePropertyModal
        property={property}
        setShowModal={setClonePropertyModal}
        showModal={clonePropertyModal}
        handleSuccess={cloneProperty}
      />

      <div className="card p-3">
        <div className="row">
          {propertyDashboardParams.map((param, index) => (
            <div key={index} className="col-lg-2 col-sm-12 col-md-6">
              <div className="d-flex align-items-center h-full">
                <div className="card-data flex h-full flex-col justify-between p-2">
                  <h5 className="pb-2"> {param.title} </h5>
                  <div className="text-2xl font-semibold text-black">
                    {param.loading ? (
                      <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
                    ) : param?.value ? (
                      <>
                        {param.value}
                        {param?.info ? param?.info : ''}
                      </>
                    ) : (
                      <span className="italic opacity-50"> - </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <div className={`col-lg-6 col-md-12`}>
          <div
            className="card p-4 bg-no-repeat bg-cover bg-right border-3 border-white flex flex-col justify-between"
            style={{ backgroundImage: 'url(' + firstCtaBg + ')' }}
          >
            <h4 className="fs-20 font-medium pb-3" style={{ color: '#3571E5' }}>
              Add bedrooms or update the latest valuation for your property
            </h4>
            <div className="flex justify-between">
              <div className="text-sm" style={{ color: '#747B8A' }}>
                Clone your property and simulate the effect of adding bedrooms or providing a more up-to-date valuation
              </div>
              <div className="flex flex-col justify-end">
                <SecondaryButton
                  className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap"
                  onClick={() => setClonePropertyModal(true)}
                >
                  <span className="inline-block w-5 h-5">
                    <CloneIcon />
                  </span>
                  <span className="pl-2.5">Simulate price</span>
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-12">
          <div
            className="card p-4 bg-no-repeat bg-cover bg-left bg-cover border-3 border-white flex flex-col justify-between"
            style={{ backgroundImage: 'url(' + secondCtaBg + ')' }}
          >
            <h4 className="fs-20 font-medium pb-3" style={{ color: '#3571E5' }}>
              Revalue property based on surrounding comparables
            </h4>
            <div className="flex justify-between" style={{ color: '#747B8A' }}>
              <div className="text-sm">
                View and refine the list of comparables used to determine the valuation for this property.
              </div>

              <div className="flex flex-col justify-end items-end w-80">
                {comparablesData?.data?.length ? (
                  <Link to={`/property-comparables/${property?.id}`}>
                    <SecondaryButton className="px-3 ml-4 min-w-56 w-auto bg-white whitespace-nowrap">
                      <span className="inline-block w-5 h-5">
                        <ComparablesIcon />
                      </span>
                      <span className="pl-2.5">See comparables</span>
                    </SecondaryButton>
                  </Link>
                ) : property?.status === 'Comparables In progress' ? (
                  <div className="flex justify-end items-center">
                    <LoaderSvg className="animate-spin h-8 w-8 text-white" />
                    <div className="ml-2 text-xs" style={{ color: '#3571E5' }}>
                      Comparables updating
                    </div>
                  </div>
                ) : comparablesLoading ? (
                  <div className="flex justify-end items-center">
                    <LoaderSvg className="animate-spin h-8 w-8 text-white" />
                  </div>
                ) : property.attempts === undefined || property.attempts < 1 ? (
                  <SecondaryButton
                    className="px-3 ml-4 min-w-56 w-auto bg-white whitespace-nowrap"
                    onClick={() => generateComparables()}
                  >
                    <span className="inline-block w-5 h-5">
                      <ComparablesIcon />
                    </span>
                    <span className="pl-2.5">Update comparables</span>
                  </SecondaryButton>
                ) : (
                  <div className="flex justify-end items-center">
                    <div className="ml-2 text-xs" style={{ color: '#3571E5' }}>
                      Generation failed
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PropertyDashboard);
