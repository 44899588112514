import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import numeral from 'numeral';
import ReactPdfSvg from './ReactPdfSvg';
import { ReactComponent as BedSVG } from 'icons/custom/bed-sm.svg';
import { ReactComponent as TagSVG } from 'icons/custom/tag.svg';
import { ReactComponent as HouseSVG } from 'icons/custom/house.svg';
import { ReactComponent as LocationSVG } from 'icons/custom/location-blue.svg';
import { propertySVGs } from '../../../../utils/helpers';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  transactionContainer: {
    marginBottom: 5,
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '8px',
  },
  card: {
    marginBottom: 5,
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 12,
  },
  text: { fontSize: 10 },
  price: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  pill: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 8,
    paddingVertical: 4,
    color: '#7B8FB7',
    fontSize: 10,
    marginRight: '20px',
  },
  priceDifference: { fontSize: 10 },
});

const PdfPropertyTransactions = ({ property, transactions }) => (
  <View>
    <Text style={styles.title}>Transaction History</Text>

    {transactions.map((transaction, index) => {
      const priceDifference = transaction.sold_price - transaction.asking_price;
      const percentageDifference = priceDifference / ((transaction.sold_price + transaction.asking_price) / 2);

      return (
        <View key={index} style={styles.transactionContainer}>
          <View style={{ ...styles.flex, alignItems: 'top' }}>
            <View>
              <Text style={{ ...styles.pill, width: 80 }}>
                {transaction.type === 'transaction' ? 'Sold' : transaction.type === 'listing' ? 'Listed' : ''}
              </Text>
            </View>

            <View style={{ ...styles.card, width: '100%' }}>
              <View style={styles.row}>
                <Text style={styles.text}>{transaction.sortingDate}</Text>
                <View style={styles.flex}>
                  <ReactPdfSvg scale={0.6}>
                    <TagSVG />
                  </ReactPdfSvg>
                  <Text style={styles.price}>
                    {numeral(
                      transaction.type === 'transaction' ? transaction.sold_price : transaction.asking_price,
                    ).format()}
                  </Text>
                </View>
              </View>

              <View style={{ ...styles.row }}>
                <Text style={styles.price}></Text>
                {transaction.asking_price && transaction.type === 'transaction' ? (
                  <Text
                    style={{
                      ...styles.priceDifference,
                      color: priceDifference > 0 ? 'green' : 'red',
                    }}
                  >
                    {priceDifference > 0 ? '+' : ''}
                    {numeral(priceDifference).format()} ({numeral(percentageDifference).format('%0')})
                  </Text>
                ) : null}
              </View>

              <View style={{ ...styles.row }}>
                <Text style={styles.text}>{transaction.modifiedRooms ? 'No. of bedrooms changed' : ''}</Text>
                <Text className="text-right"> {transaction.type === 'listing' ? 'Listed Price' : ''}</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.flex}>
                  <ReactPdfSvg scale={1}>
                    <LocationSVG />
                  </ReactPdfSvg>
                  <Text style={styles.text}>District: {property?.property_index_data?.district_code || 'N/A'}</Text>
                </View>

                <View style={styles.flex}>
                  <ReactPdfSvg scale={0.6}>
                    <HouseSVG />
                  </ReactPdfSvg>
                  <Text style={styles.text}>{propertySVGs[transaction.property_type]?.name} </Text>
                </View>

                {transaction.bedrooms !== undefined && (
                  <View style={styles.flex}>
                    <ReactPdfSvg scale={1}>
                      <BedSVG />
                    </ReactPdfSvg>
                    <Text style={styles.text}>Bedrooms: {transaction.bedrooms}</Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      );
    })}
  </View>
);

export default PdfPropertyTransactions;
