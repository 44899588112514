import { ReactComponent as DetachedSVG } from 'images/properties/detached.svg';
import { ReactComponent as SemiDetachedSVG } from 'images/properties/semi-detached.svg';
import { ReactComponent as FlatSVG } from 'images/properties/flat.svg';
import { ReactComponent as TerracedSVG } from 'images/properties/terraced.svg';
import { ReactComponent as ChevronLeft } from 'icons/custom/chevron-left.svg';

import moment from 'moment';
import { std, pow, sqrt, mean } from 'mathjs';
import numeral from 'numeral';
import { GOOGLE_MAPS_API_KEY } from './constants';

export function seriesObjectToArray(object, targetTime) {
  let forecastArray = [];
  for (const [key, value] of Object.entries(object || {})) {
    forecastArray.push({
      timestamp: key,
      value: value,
    });
  }
  return trimDataArray(forecastArray, targetTime);
}

export function trimDataArray(forecastData, targetTime) {
  const minDate = moment(targetTime).subtract(5, 'years');
  const maxDate = moment(targetTime).add(2, 'years');

  const trimmedForecast = forecastData
    .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
    .filter(({ timestamp }) => moment(timestamp).isBetween(minDate, maxDate, 'month', '(]'));

  updateValuesWithMovingAverage(targetTime, trimmedForecast);

  return trimmedForecast;
}

export function formatRentalYieldData(rentalForecast, priceForecast, targetTime) {
  const forecastArray = [];
  for (const [key, value] of Object.entries(rentalForecast || {})) {
    const price = priceForecast[key];
    const forecastValue = price > 0 ? (value * 12) / price : 0;
    forecastArray.push({
      timestamp: key,
      value: forecastValue,
    });
  }
  return trimDataArray(forecastArray, targetTime);
}

export function calculateComparablesCenterPoint(coordinates) {
  if (!coordinates || coordinates.length === 0) {
    return null;
  }

  let sumLat = 0;
  let sumLng = 0;

  for (const coordinate of coordinates) {
    sumLat += parseFloat(coordinate.latitude);
    sumLng += parseFloat(coordinate.longitude);
  }

  const avgLat = sumLat / coordinates.length;
  const avgLng = sumLng / coordinates.length;

  return { lat: avgLat, lng: avgLng };
}

export function calculateCenterPoint(coordinates) {
  if (!coordinates || coordinates.length === 0) {
    return null;
  }

  let sumLat = 0;
  let sumLng = 0;

  for (const coordinate of coordinates) {
    sumLat += coordinate.latitude;
    sumLng += coordinate.longitude;
  }

  const avgLat = sumLat / coordinates.length;
  const avgLng = sumLng / coordinates.length;

  return { lat: avgLat, lng: avgLng };
}

export const propertySVGs = {
  F: {
    component: <FlatSVG />,
    name: 'Flat',
  },
  T: {
    component: <TerracedSVG />,
    name: 'Terraced',
  },
  S: {
    component: <SemiDetachedSVG />,
    name: 'Semi-Detached',
  },
  D: {
    component: <DetachedSVG />,
    name: 'Detached',
  },
};

export const demographicsInfoData = {
  district_anti_social_behaviour: {
    title: 'Anti-social Behaviour',
    info: 'Number of anti-social behaviour offences recorded per month within the district_code district',
    min: 1.0,
    max: 843.5,
  },
  district_broadband_speed: {
    title: 'Broadband Speed',
    info: 'Average broadband speed in the district_code district',
    min: 2.01,
    max: 424.01,
  },
  district_income_age_all: {
    title: 'Monthly Income',
    info: 'Monthly income of residents within the district_code district, averaged over the last six months',
    min: 986.821667,
    max: 5174.53,
  },
  district_median_age: {
    title: 'Median Age',
    info: 'Median age of resident within the district_code district, averaged over the last six months',
    min: 21.0,
    max: 62.0,
  },
  district_public_disorder_and_weapons: {
    title: 'Public Disorder and Weapons Crime',
    info: 'Number of public disorder and weapons offences recorded per month within the district_code district, averaged over the last six months',
    min: 1.0,
    max: 69.0,
  },
  district_unemployment: {
    title: 'Unemployment',
    info: 'Number of unemployed residents within the district_code district, averaged over the last six months',
    min: 2.0,
    max: 10235.0,
  },
  district_vehicle_crime: {
    title: 'Vehicle Crime',
    info: 'Number of vehicle crimes recorded per month within the district_code district, averaged over the last six months',
    min: 1.0,
    max: 226.666667,
  },
  district_violent_crime: {
    title: 'Violent Crime',
    info: 'Number of violent crimes recorded per month within the district_code district, averaged over the last six months',
    min: 1.0,
    max: 315.333333,
  },
  district_proportion_renters_buyers_s: {
    title: 'Share of People Owning their Home',
    info: 'Percentage of individuals residing in self-owned households within the district_code district',
    min: 0,
    max: 100,
  },
};

export const getStatisticalData = (forecast, targetTime) => {
  let returnsArray = [];
  for (let count = 11; count >= 0; count--) {
    const currentDate = moment(targetTime)
      .subtract(count + 1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const nextDate = moment(targetTime).subtract(count, 'months').startOf('month').format('YYYY-MM-DD');
    const currentValue = forecast[currentDate];
    const nextValue = forecast[nextDate];
    const returnDiff = nextValue / currentValue - 1;
    returnsArray.push(returnDiff);
  }

  return {
    stdev: std(returnsArray),
    average: mean(returnsArray),
  };
};

export const formatKeyPerformanceObject = (data, riskFreeRate, targetTime) => {
  const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
  const formattedBeginningDate = moment(targetTime).subtract(59, 'months').startOf('month').format('YYYY-MM-DD');
  const formattedEndDate = moment(targetTime).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  const formattedStartOfYear = moment(targetTime).startOf('year').format('YYYY-MM-DD');

  const valueAtTargetTime = data?.forecast[formattedCurrentDate];
  const beginningValue = data?.forecast[formattedBeginningDate];
  const valueAtTheEnd = data?.forecast[formattedEndDate];
  const valueAtStartOfYear = data?.forecast[formattedStartOfYear];

  const { stdev, average } = getStatisticalData(data.forecast, targetTime);

  const volatility = sqrt(12) * stdev;
  const cagr = pow(valueAtTheEnd / beginningValue, 1 / 5) - 1;

  const last12MonthReturns = 12 * average;
  const sharpe = (last12MonthReturns - riskFreeRate) / volatility;

  return {
    riskFreeRate: riskFreeRate,
    cumulativeReturn: (valueAtTheEnd - beginningValue) / beginningValue,
    cagr: cagr,
    sharpe: sharpe,
    oneMonthReturn: data?.chg_1m || data['%_chg_1m'],
    YTDReturns: (valueAtTargetTime - valueAtStartOfYear) / valueAtStartOfYear,
    last12MonthReturns: last12MonthReturns,
    expected12MonthReturns: data?.est_chg_1y || data?.est_chg_fy1y,
    lifetimeReturns: data?.expected_return / 100,
    expected24MonthReturns: data?.est_chg_2y || data?.est_chg_fy2y,
    estimatedSaleValue: valueAtTargetTime,
    volatility: volatility,
  };
};

export function cartesian(...a) {
  return a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())));
}

export function compareNumericString(rowA, rowB, id, desc) {
  let a = Number.parseFloat(numeral(rowA.values[id]).value());
  let b = Number.parseFloat(numeral(rowB.values[id]).value());

  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

export function generateCsvByTableData(tableData) {
  const rows = tableData.map((item) => {
    return [
      item.location,
      item.uprn,
      item.value,
      item.highAccuracy ? 'High accuracy: between 1% - 5%' : 'Medium accuracy: between 5% - 10%',
      item.benchmarkValue,
      item.indexRentEstimated,
      item.rentalYield,
      item.actualRent,
      item.actualRentalYield,
      item.propertyType,
      item.number_of_bedrooms,
      item.districtCode,
      item.chg1m,
      item.chg3m,
      item.chg6m,
      item.chg1y,
      item.weightPercentage,
      item.lastTransactionPrice,
      item.lastTransactionDate,
    ];
  });

  rows.unshift([
    'Location',
    'UPRN',
    'Our valuation estimate',
    'Accuracy of valuation estimate',
    'Benchmark Value',
    'Index Rental Estimate',
    'Rental Yield',
    'Actual Rent',
    'Actual Rental Yield',
    'Property Type',
    'Number of Bedrooms',
    'District Code',
    '%Chg 1M',
    '%Chg 3M',
    '%Chg 6M',
    '%Chg 1Y',
    'Portfolio Weight Percentage',
    'Last Transaction Price',
    'Last Transaction Date',
  ]);

  let csvContent =
    'data:text/csv;charset=utf-8,' +
    '\ufeff' +
    rows
      .map((e) => {
        e = e.map((item) => `"${item.replace('£', '\u00A3')}"`);
        return e.join(',');
      })
      .join('\n');
  var encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
}

export const getCarouselProps = () => ({
  showArrows: true,
  showThumbs: true,
  infiniteLoop: true,
  dynamicHeight: true,
  showStatus: false,
  showIndicators: false,
  swipeable: true,
  emulateTouch: true,
  renderArrowPrev: (clickHandler, hasPrev) => {
    return (
      <div
        className={`${
          hasPrev ? 'absolute' : 'hidden'
        } inset-y-1/2 left-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-20 m-3 h-10 w-10`}
        onClick={clickHandler}
      >
        <ChevronLeft className="w-6 h-6" />
      </div>
    );
  },
  renderArrowNext: (clickHandler, hasNext) => {
    return (
      <div
        className={`${
          hasNext ? 'absolute' : 'hidden'
        } inset-y-1/2 right-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-20 m-3 h-10 w-10`}
        onClick={clickHandler}
      >
        <ChevronLeft className="w-6 h-6" style={{ transform: 'rotate(180deg)' }} />
      </div>
    );
  },
});

export const getMapCarouselProps = (parameters) => ({
  showArrows: true,
  showThumbs: true,
  infiniteLoop: true,
  dynamicHeight: true,
  showStatus: false,
  showIndicators: false,
  swipeable: true,
  emulateTouch: true,
  renderArrowPrev: (clickHandler, hasPrev) => {
    return (
      <div
        className={`${
          hasPrev ? 'absolute' : 'hidden'
        } inset-y-1/2 left-0 flex justify-center items-center rounded-md bg-white shadow-lg hover:opacity-80 cursor-pointer z-20 mx-2 h-6 w-6`}
        onClick={clickHandler}
      >
        <ChevronLeft className="w-3 h-3" />
      </div>
    );
  },
  renderArrowNext: (clickHandler, hasNext) => {
    return (
      <div
        className={`${
          hasNext ? 'absolute' : 'hidden'
        } inset-y-1/2 right-0 flex justify-center items-center rounded-md bg-white shadow-lg hover:opacity-80 cursor-pointer z-20 mx-2 h-6 w-6`}
        onClick={clickHandler}
      >
        <ChevronLeft className="w-3 h-3" style={{ transform: 'rotate(180deg)' }} />
      </div>
    );
  },
  ...parameters,
});

export const postTownOptions = [
  { value: 'UK', label: 'UK' },
  { value: 'LONDON', label: 'London' },
  { value: 'MANCHESTER', label: 'Manchester' },
  { value: 'BIRMINGHAM', label: 'Birmingham' },
  { value: 'LIVERPOOL', label: 'Liverpool' },
  { value: 'CARDIFF', label: 'Cardiff' },
  { value: 'NEWCASTLE', label: 'Newcastle' },
];

export const calculateMonthlyChange = (data, targetTime) => {
  const dates = Object.keys(data);
  const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
  const forecastArray = [];
  for (let i = 1; i < sortedDates.length; i++) {
    const currentDate = sortedDates[i];
    const previousDate = sortedDates[i - 1];
    const currentValue = data[currentDate];
    const previousValue = data[previousDate];
    const change = (currentValue - previousValue) / previousValue;

    forecastArray.push({
      timestamp: currentDate,
      value: change,
    });
  }

  const result = trimDataArray(forecastArray, targetTime);
  return result;
};

export const calculateYearlyChange = (data, targetTime) => {
  const dates = Object.keys(data);
  const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
  let forecastArray = [];
  for (let i = 13; i < sortedDates.length; i++) {
    const currentDate = sortedDates[i];
    const previousDate = sortedDates[i - 12];
    const currentValue = data[currentDate];
    const previousValue = data[previousDate];
    const change = (currentValue - previousValue) / previousValue;

    forecastArray.push({
      timestamp: currentDate,
      value: change,
    });
  }

  const result = trimDataArray(forecastArray, targetTime);
  return result;
};
export const regionOptions = [
  { value: 'uk', label: 'UK' },
  { value: 'england', label: 'England' },
  { value: 'scotland', label: 'Scotland' },
  { value: 'wales', label: 'Wales' },
  { value: 'northern_ireland', label: 'Northern Ireland' },
];

const updateValuesWithMovingAverage = (date, dataArray) => {
  const startIndex = dataArray.findIndex((item) => item.timestamp === moment(date).format('YYYY-MM-DD'));

  if (startIndex === -1 || startIndex >= dataArray.length - 1) {
    return;
  }

  for (let i = startIndex + 1; i < dataArray.length; i++) {
    dataArray[i].value = (dataArray[i - 1].value + dataArray[i].value) / 2;
  }

  dataArray.pop();
};

const WORLD_DIM = { height: 640, width: 340 };

export const generateStaticMapURL = (properties) => {
  const markers = properties
    .map((property, index) => {
      return `&markers=color:red%7Clabel:${(index + 10).toString(36).toUpperCase()}%7C${property.latitude},${property.longitude}`;
    })
    .join('');
  const center = properties.length > 0 ? `${properties[0].latitude},${properties[0].longitude}` : '';
  const data = calculateBoundsAndZoom(properties);
  const zoomLevel = `&zoom=${data.zoom}`;
  return `https://maps.googleapis.com/maps/api/staticmap?key=${GOOGLE_MAPS_API_KEY}&center=${center}&size=${WORLD_DIM.height}x${WORLD_DIM.width}&scale=4${zoomLevel}${markers}`;
};

const calculateBoundsAndZoom = (properties) => {
  const bounds = new window.google.maps.LatLngBounds();
  for (let i = 0; i < properties.length; i++) {
    const marker = properties[i];
    if (marker?.latitude && marker?.longitude) {
      const newPoint = new window.google.maps.LatLng(marker.latitude, marker.longitude);
      bounds.extend(newPoint);
    }
  }
  const zoom = getZoomByBounds(bounds);
  return { bounds, zoom };
};

const getZoomByBounds = (bounds) => {
  const ZOOM_MAX = 21;

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(400, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(600, WORLD_DIM.width, lngFraction);

  const result = Math.min(latZoom, lngZoom, ZOOM_MAX);
  return result;
};

const latRad = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const zoom = (mapPx, worldPx, fraction) => {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
};

export function formatRentsData(data) {
  let result = { Flat: {}, House: {} };

  data.forEach((item) => {
    const { type, number_of_bedrooms } = item;

    const label = number_of_bedrooms === 0 ? 'Studio' : `${number_of_bedrooms} Bed`;

    if (!result[type]) result[type] = {};
    if (!result[type][label]) result[type][label] = {};

    result[type][label] = {
      avgRentPaid12m: item.avg_rent_paid_12m,
      avgRentTop25: item.avg_rent_paid_12m_top25,
      avgRentTop10: item.avg_rent_paid_12m_top10,
      lowerQuartile: item.lower_quartile,
      medianQuartile: item.median_quartile,
      upperQuartile: item.upper_quartile,
      mostActiveRentalBand: item.most_active_rental_band,
    };
  });

  return result;
}

export function formatSalesData(data) {
  let result = { Flat: {}, House: {} };

  data.forEach((item) => {
    const { type, number_of_bedrooms } = item;

    const label = number_of_bedrooms === 0 ? 'Studio' : `${number_of_bedrooms} Bed`;

    if (!result[type]) result[type] = {};
    if (!result[type][label]) result[type][label] = {};

    result[type][label] = {
      avgPricePaid12m: item.avg_price_paid_12m,
      avgPriceTop25: item.avg_price_paid_12m_top25,
      avgPriceTop10: item.avg_price_paid_12m_top10,
      lowerQuartile: item.lower_quartile,
      medianQuartile: item.median_quartile,
      upperQuartile: item.upper_quartile,
      mostActivePriceBand: item.most_active_price_band,
    };
  });

  return result;
}

export function combineRentsData(districtData, areaData) {
  const result = {};

  const propertyTypes = new Set([...Object.keys(districtData), ...Object.keys(areaData)]);

  propertyTypes.forEach((type) => {
    const districtTypeData = districtData[type] || {};
    const areaTypeData = areaData[type] || {};

    result[type] = {};

    const labels = new Set([...Object.keys(districtTypeData), ...Object.keys(areaTypeData)]);

    labels.forEach((label) => {
      const districtLabelData = districtTypeData[label] || {};
      const areaLabelData = areaTypeData[label] || {};

      result[type][label] = {
        ...Object.fromEntries(
          Object.entries(districtLabelData).map(([key, value]) => [
            key,
            { district: value, area: areaLabelData[key] || null },
          ]),
        ),
        ...Object.fromEntries(
          Object.entries(areaLabelData)
            .filter(([key]) => !districtLabelData[key])
            .map(([key, value]) => [key, { district: null, area: value }]),
        ),
      };
    });
  });

  return result;
}
