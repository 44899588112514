import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';

import PriceChangeMapView from 'jsx/components/Property/Components/price-change-map/PriceChangeMap';

const PriceChange = () => {
  const { propertyId } = useParams();

  const { data: property } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  if (!property) {
    return <></>;
  }

  return (
    <>
      {property.latitude && property.longitude && (
        <PriceChangeMapView
          propertyType={property.property_index_data?.property_type}
          numberOfBedrooms={
            property.property_index_data?.number_of_bedrooms &&
            parseInt(property.property_index_data?.number_of_bedrooms)
          }
          districtSelected={property.property_index_data?.district_code}
          center={{ lat: property.latitude, lng: property.longitude }}
        />
      )}
    </>
  );
};

export default PriceChange;
