import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';

import RentalMarketReport from 'jsx/components/RentalMarketReport/RentalMarketReport';
import {
  apiEmployementStatusPercentages,
  apiHouseholdPercentages,
  apiRentalMarketArea,
  apiRentalMarketDistrict,
  apiTenurePercentagesHousehold,
} from 'api/avm';
import { combineRentsData, formatRentsData } from 'utils/helpers';
import { useMemo } from 'react';

const RentalMarket = ({ onChartImagesChange }) => {
  const { propertyId } = useParams();

  const { data: property } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  const { district_code, area_code, property_type, number_of_bedrooms } = property?.property_index_data || {};

  const districtQuery = {
    district: district_code,
    limit: 20,
    offset: 0,
  };
  const areaQuery = {
    area_code: area_code,
    limit: 20,
    offset: 0,
  };

  const {
    data: rentalMarketDistrict = [],
    isLoading: rentalMarketDistrictDataLoading,
    isSuccess: rentalMarketDistrictDataLoaded,
  } = useQuery(
    ['rentalMarketDistrict', districtQuery],
    ({ signal }) => apiRentalMarketDistrict(districtQuery, signal),
    {
      enabled: !!district_code,
    },
  );

  const {
    data: rentalMarketArea = [],
    isLoading: rentalMarketAreaDataLoading,
    isSuccess: rentalMarketAreaDataLoaded,
  } = useQuery(['rentalMarketArea', areaQuery], ({ signal }) => apiRentalMarketArea(areaQuery, signal), {
    enabled: !!area_code,
  });

  const combinedData = useMemo(() => {
    if (rentalMarketDistrictDataLoaded && rentalMarketAreaDataLoaded) {
      const districtFormattedData = formatRentsData(rentalMarketDistrict);
      const areaFormattedData = formatRentsData(rentalMarketArea);

      return combineRentsData(districtFormattedData, areaFormattedData);
    } else {
      return { Flat: {}, House: {} };
    }
  }, [rentalMarketDistrict, rentalMarketArea, rentalMarketDistrictDataLoaded, rentalMarketAreaDataLoaded]);

  const isLoading = rentalMarketDistrictDataLoading || rentalMarketAreaDataLoading;

  const { data: employmentStatusData = [], isLoading: employmentStatusDataLoading } = useQuery(
    ['districtEmploymentData', { district: district_code }],
    ({ signal }) => apiEmployementStatusPercentages({ district: district_code }, signal),
    {
      enabled: !!district_code,
    },
  );

  const uniqueEmploymentData = employmentStatusData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.employment_group === item.employment_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const employmentCategories = uniqueEmploymentData.map((item) => item.employment_group);
  const employmentDistrictData = uniqueEmploymentData.reduce((acc, item) => {
    acc[item.employment_group] = (item.percentage_by_district || 0) / 100;
    return acc;
  }, {});

  const employmentAreaData = uniqueEmploymentData.reduce((acc, item) => {
    acc[item.employment_group] = (item.percentage_by_area || 0) / 100;
    return acc;
  }, {});

  const { data: householdData = [], isLoading: householdDataLoading } = useQuery(
    ['districtHouseholdData', { district: district_code }],
    ({ signal }) => apiHouseholdPercentages({ district: district_code }, signal),
    {
      enabled: !!district_code,
    },
  );

  const uniqueHouseholdData = householdData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.household_group === item.household_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const householdCategories = uniqueHouseholdData.map((item) => item.household_group);
  const householdDistrictData = uniqueHouseholdData.reduce((acc, item) => {
    acc[item.household_group] = (item.percentage_by_district || 0) / 100;
    return acc;
  }, {});

  const householdAreaData = uniqueHouseholdData.reduce((acc, item) => {
    acc[item.household_group] = (item.percentage_by_area || 0) / 100;
    return acc;
  }, {});

  const { data: tenureData = [], isLoading: tenureDataLoading } = useQuery(
    ['districtTenureData', { district: district_code }],
    ({ signal }) => apiTenurePercentagesHousehold({ district: district_code }, signal),
    {
      enabled: !!district_code,
    },
  );

  const uniqueTenureData = tenureData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.tenure_group === item.tenure_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const tenureCategories = uniqueTenureData.map((item) => item.tenure_group);
  const tenureDistrictData = uniqueTenureData.reduce((acc, item) => {
    acc[item.tenure_group] = (item.percentage_by_district || 0) / 100;
    return acc;
  }, {});

  const tenureAreaData = uniqueTenureData.reduce((acc, item) => {
    acc[item.tenure_group] = (item.percentage_by_area || 0) / 100;
    return acc;
  }, {});

  if (!property) {
    return <></>;
  }

  return (
    <>
      <RentalMarketReport
        district_code={district_code}
        area_code={area_code}
        property_type={property_type}
        number_of_bedrooms={number_of_bedrooms}
        employmentCategories={employmentCategories}
        employmentDistrictData={employmentDistrictData}
        employmentAreaData={employmentAreaData}
        householdCategories={householdCategories}
        householdDistrictData={householdDistrictData}
        householdAreaData={householdAreaData}
        tenureCategories={tenureCategories}
        tenureDistrictData={tenureDistrictData}
        tenureAreaData={tenureAreaData}
        rentalMarketDistrict={rentalMarketDistrict}
        rentalMarketArea={rentalMarketArea}
        combinedData={combinedData}
        employmentStatusDataLoading={employmentStatusDataLoading}
        householdDataLoading={householdDataLoading}
        tenureDataLoading={tenureDataLoading}
        isLoading={isLoading}
        onChartImagesChange={onChartImagesChange}
      />
    </>
  );
};

export default RentalMarket;
