import React, { useMemo, useState } from 'react';

import { useEffect } from 'react';
import { getPropertyIndicesbyUPRN, getTargetTime } from '../../../api/propertyIndex';
import { apiGetRawUserProperties, createProperty, createPropertyByCoordinates } from '../../../api/property';

import moment from 'moment';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { ReactComponent as BackSVG } from 'icons/custom/arrow-back.svg';

import LocationSearchAutocomplete from '../Dashboard/Forms/LocationSearchAutocomplete';
import PropertyModelRunModal from '../Dashboard/Modals/PropertyModelRunModal';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';
import { Link } from 'react-router-dom';
import SearchByCoordinatesModal from '../Dashboard/Modals/SearchByCoordinatesModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import StatusModal from '../Dashboard/Modals/StatusModal';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

var propertyTypes = ['Flat', 'Terraced', 'Semi-Detached', 'Detached'];

const emptyValue = {
  address: '',
  uprn: '',
  purchase_price: 0,
  instant_realyse_price: 0,
  purchase_date: '',
  num_rooms: 0,
  debt: 0,
  monthly_income: 0,
  average_listed_price: 0,
  last_transaction_price: 0,
  property_type: '',
  occupancy: 0,
  monthly_expense: 0,
  property_id: undefined,
  epc: '',
};

const NewProperty = () => {
  const [addFormData, setAddFormData] = useState(emptyValue);

  const [propertyParams, setPropertyParams] = useState({
    uprn: undefined,
    address: undefined,
    num_rooms: undefined,
    property_id: undefined,
    property_type: undefined,
    epc: undefined,
  });
  const [propertyModelRunModal, setPropertyModelRunModal] = useState(false);
  const [resetSearchField, setResetSearchField] = useState(false);
  const [searchByCoordinatesModal, setSearchByCoordinatesModal] = useState(false);
  const [missingParams, setMissingParams] = useState(false);
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const queryClient = useQueryClient();

  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));
  const {
    data: rawProperties = [],
    isSuccess: rawPropertiesLoaded,
    refetch: refetchProperties,
  } = useQuery(['rawProperties'], ({ signal }) => apiGetRawUserProperties(signal));

  const existingPropertyUprns = useMemo(() => {
    if (rawProperties && rawPropertiesLoaded) {
      return rawProperties.map((item) => item.uprn);
    } else {
      return [];
    }
  }, [rawProperties, rawPropertiesLoaded]);

  const addProperty = async () => {
    try {
      const formattedData = { ...addFormData };
      formattedData.address = addFormData.address;
      formattedData.uprn = addFormData.uprn;
      formattedData.average_listed_price = Math.trunc(numeral(addFormData?.average_listed_price).value());
      formattedData.instant_realyse_price = Math.trunc(numeral(addFormData?.instant_realyse_price).value());
      formattedData.last_transaction_price = Math.trunc(numeral(addFormData?.last_transaction_price).value());
      formattedData.purchase_price = Math.trunc(numeral(addFormData?.purchase_price).value());
      formattedData.property_type = addFormData?.property_type.charAt(0).toUpperCase();
      formattedData.occupancy = parseInt(addFormData?.occupancy);
      formattedData.debt = Math.trunc(numeral(addFormData?.debt).value());
      formattedData.monthly_expense = Math.trunc(numeral(addFormData?.monthly_expense).value());
      formattedData.monthly_income = Math.trunc(numeral(addFormData?.monthly_income).value());
      formattedData.num_rooms = parseInt(addFormData?.num_rooms);

      await createProperty({
        ...formattedData,
        comparables: {
          uprn: addFormData.uprn,
        },
      });
      const queryState = queryClient.getQueryState(['properties']);
      if (queryState) {
        queryClient.refetchQueries(['properties']);
      }
      queryClient.invalidateQueries(['dashboardData']);
      queryClient.invalidateQueries(['rawProperties']);
      queryClient.invalidateQueries(['rawProperties', 'in-progress']);

      refetchProperties();
      setStatusModalContent({
        show: true,
        type: 'success',
        description: 'Property successfully added',
      });
    } catch (error) {
      console.error(error);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  const clearNewPropertyForm = () => {
    setAddFormData(emptyValue);
  };

  useEffect(() => {
    clearNewPropertyForm();
  }, []);

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleAddFormPercentChange = (event) => {
    event.preventDefault();
    const { value, min, max } = event.target;
    const resultValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    const newFormData = { ...addFormData };
    newFormData.occupancy = resultValue;
    setAddFormData(newFormData);
  };

  const handleAddFormPercentFocus = (event) => {
    event.preventDefault();
    const newFormData = { ...addFormData };
    newFormData.occupancy = event.target.value.slice(0, -1);
    setAddFormData(newFormData);
  };

  const handleAddFormPercentBlur = (event) => {
    event.preventDefault();
    const newFormData = { ...addFormData };
    newFormData.occupancy = event.target.value + '%';
    setAddFormData(newFormData);
  };

  const handleOnAutocompleteSelect = async function (item) {
    setResetSearchField(false);
    if (item.uprn === '0') {
      item.uprn = undefined;
    }
    setPropertyParams((prevState) => ({
      ...prevState,
      uprn: item.uprn,
      address: item.address,
      num_rooms: item.num_rooms,
      property_id: item.property_id,
      property_type: item.property_type,
      latitude: item.latitude,
      longitude: item.longitude,
      district_code: item.district,
      post_town: item.post_town,
      post_code: item.post_code,
      is_habitable_rooms: item.is_habitable_rooms,
      epc: item.epc,
    }));

    if (item.present) {
      try {
        const response = await getPropertyIndicesbyUPRN(item.uprn);
        const resultItem = response?.data?.data[0];
        if (resultItem) {
          const newFormData = {};
          const formattedDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
          const propertyValueToday = resultItem?.forecast[formattedDate] || 0;
          const monthlyIncome = (propertyValueToday * 0.035) / 12;

          newFormData['address'] = item.address;
          newFormData['uprn'] = item.uprn;
          newFormData['purchase_price'] = propertyValueToday;
          newFormData['instant_realyse_price'] =
            propertyValueToday && Math.abs(resultItem?.instant_purchase_via_realyse_price / propertyValueToday) <= 0.2
              ? resultItem?.instant_purchase_via_realyse_price
              : undefined;
          newFormData['purchase_date'] = /^\d{2}\/\d{2}\/\d{4}$/.test(resultItem?.last_transaction_date)
            ? moment(resultItem?.last_transaction_date, 'MM/DD/YYYY').format('YYYY-MM-DD')
            : resultItem?.last_transaction_date;
          newFormData['num_rooms'] = parseInt(resultItem?.number_of_bedrooms);
          newFormData['debt'] = 0.6 * propertyValueToday;
          newFormData['rental_avm'] = resultItem?.rental_avm;
          newFormData['average_listed_price'] = resultItem?.listed_average_price;
          newFormData['last_transaction_price'] =
            resultItem?.last_transaction_price && Number(resultItem?.last_transaction_price) > 0
              ? resultItem?.last_transaction_price
              : 0;
          newFormData['property_type'] = resultItem?.property_type?.charAt(0)?.toUpperCase();
          newFormData['occupancy'] = '60%';
          newFormData['monthly_expense'] = 0.6 * monthlyIncome; // TODO: clarify value calculation principle
          newFormData['property_id'] = item.property_id;
          newFormData['epc'] = item.epc;
          newFormData['latitude'] = item.latitude;
          newFormData['longitude'] = item.longitude;
          newFormData['district_code'] = item.district;
          newFormData['post_town'] = item.post_town;
          newFormData['post_code'] = item.post_code;
          newFormData['is_habitable_rooms'] = item.is_habitable_rooms;

          setAddFormData(newFormData);
        } else {
          setResetSearchField(true);
          clearNewPropertyForm();
          setPropertyModelRunModal(true);
          refetchProperties();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setResetSearchField(true);
      setMissingParams(true);
      setPropertyModelRunModal(true);
    }
  };

  const searchByCoordinatesModalResult = async (data) => {
    try {
      const result = await createPropertyByCoordinates({ ...data, custom: true, user_entered_params: '1' });

      if (result?.data?.data) {
        const queryState = queryClient.getQueryState(['properties']);
        if (queryState) {
          queryClient.refetchQueries(['properties']);
        }
        queryClient.invalidateQueries(['dashboardData']);
        queryClient.invalidateQueries(['rawProperties']);
        queryClient.invalidateQueries(['rawProperties', 'in-progress']);
        refetchProperties();
        setStatusModalContent({
          show: true,
          type: 'success',
          description: `Valuation data for this property is now being generated.
          You'll receive an email notification when it's available.
          This process normally takes 1-2 minutes but may sometimes take a little longer.`,
          action: (
            <Link to={'/properties/'}>
              <PrimaryButton>View Properties</PrimaryButton>
            </Link>
          ),
        });
      }
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const error = false;
    const errorMsg = '';
    if (!error) {
      addProperty();
      clearNewPropertyForm();
      setResetSearchField(true);
    } else {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: errorMsg,
      });
    }
  };

  const labelClassName = 'font-medium	text-black text-xs';
  const inputClassName = 'form-control rounded-lg font-normal text-xs';
  const inputStyle = {
    border: '1px solid #DFE1E5',
    color: '#747B8A',
  };

  return (
    <div className="" role="document">
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <SearchByCoordinatesModal
        setShowModal={setSearchByCoordinatesModal}
        showModal={searchByCoordinatesModal}
        handleSuccess={searchByCoordinatesModalResult}
      />
      <PropertyModelRunModal
        setShowModal={setPropertyModelRunModal}
        showModal={propertyModelRunModal}
        propertyParams={propertyParams}
        missingParams={missingParams}
      />
      <Link className="text-primary flex items-center mb-4" to="/properties">
        <BackSVG className="mr-2" />
        <span className="pl-2 text-gray-500 text-base"> All Properties </span>
      </Link>
      <div className="bg-white rounded-lg">
        <form>
          <div className="px-8 py-4">
            <h4 className="fs-20">Add New Property</h4>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3 mx-2">
                  <label className="text-black font-w500">Property Address</label>
                  <div className="contact-name">
                    <LocationSearchAutocomplete
                      allAddresses={true}
                      existingUprns={existingPropertyUprns}
                      resetSearch={resetSearchField}
                      onSelect={(selectedItem) => {
                        handleOnAutocompleteSelect(selectedItem);
                      }}
                    />
                  </div>
                  <div className="text-black text-xs pb-2 pt-3">
                    If providing a postcode, exclude any spaces or dashes (e.g. SW82LB). Couldn't find the address?{' '}
                    <span
                      className="cursor-pointer"
                      style={{ color: '#3571e5' }}
                      onClick={() => setSearchByCoordinatesModal(true)}
                    >
                      Search property by coordinates
                    </span>
                  </div>
                </div>

                <div className="flex w-full flex-row">
                  <div className="flex w-1/3 flex-col">
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Last Transaction Date</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="purchase_date"
                          required="required"
                          disabled
                          onChange={handleAddFormChange}
                          value={
                            addFormData?.purchase_date
                              ? moment(addFormData?.purchase_date).isBefore(moment())
                                ? addFormData?.purchase_date
                                : '-'
                              : '-'
                          }
                          placeholder="Enter Last Transaction Date"
                          style={inputStyle}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Debt</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="debt"
                          required="required"
                          onChange={handleAddFormChange}
                          value={addFormData?.debt ? numeral(addFormData?.debt).format() : '-'}
                          disabled
                          placeholder="Debt"
                          style={inputStyle}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Occupancy</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="occupancy"
                          required="required"
                          onFocus={handleAddFormPercentFocus}
                          onBlur={handleAddFormPercentBlur}
                          onChange={handleAddFormPercentChange}
                          value={addFormData?.occupancy ? addFormData?.occupancy : '-'}
                          placeholder="Occupancy Percentage"
                          style={inputStyle}
                          disabled
                          min="0"
                          max="100"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-1/3 flex-col">
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Last Transaction Price</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="last_transaction_price"
                          required="required"
                          value={
                            addFormData?.last_transaction_price
                              ? numeral(addFormData?.last_transaction_price).format()
                              : '-'
                          }
                          onChange={handleAddFormChange}
                          style={inputStyle}
                          disabled
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Average Listed Price</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="average_listed_price"
                          required="required"
                          value={
                            addFormData?.average_listed_price
                              ? numeral(addFormData?.average_listed_price).format()
                              : '-'
                          }
                          style={inputStyle}
                          disabled
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Property Type</label>
                      <div className="contact-name">
                        <select
                          name={'property_type'}
                          onChange={handleAddFormChange}
                          value={addFormData?.property_type}
                          className="form-select h-14"
                          style={inputStyle}
                          disabled
                        >
                          {propertyTypes.map((itemValue, index) => {
                            return (
                              <option key={index} value={itemValue[0]}>
                                {itemValue}
                              </option>
                            );
                          })}
                        </select>
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Monthly Expenses</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="monthly_expense"
                          required="required"
                          onChange={handleAddFormChange}
                          value={addFormData?.monthly_expense ? numeral(addFormData?.monthly_expense).format() : '-'}
                          placeholder="Monthly Expenses"
                          style={inputStyle}
                          disabled
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-1/3 flex-col">
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Our valuation estimate</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="purchase_price"
                          required="required"
                          onChange={handleAddFormChange}
                          value={addFormData?.purchase_price ? numeral(addFormData?.purchase_price).format() : '-'}
                          placeholder="Enter Estimated Price"
                          style={inputStyle}
                          disabled
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>
                        {addFormData.is_habitable_rooms ? 'Number of habitable rooms' : 'Number of bedrooms'}
                      </label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="num_rooms"
                          required="required"
                          onChange={handleAddFormChange}
                          value={addFormData?.num_rooms ? addFormData?.num_rooms : '-'}
                          placeholder="Number Of Rooms"
                          style={inputStyle}
                          disabled
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3 h-20 mx-2">
                      <label className={labelClassName}>Our rental estimate</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className={inputClassName}
                          autoComplete="off"
                          name="rental_avm"
                          required="required"
                          onChange={handleAddFormChange}
                          value={addFormData?.rental_avm ? numeral(addFormData?.rental_avm).format() : '-'}
                          placeholder="Our rental estimate"
                          style={inputStyle}
                          disabled
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex px-8 py-4 justify-end">
            <PrimaryButton
              className={'px-14 w-auto'}
              type="submit"
              onClick={handleAddFormSubmit}
              disabled={!addFormData?.property_type}
            >
              Add Property
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewProperty;
