import { useQuery } from '@tanstack/react-query';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { apiSearchBenchmarkAreaValuation } from 'api/avm';
import { combineRentsData, formatSalesData } from 'utils/helpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SalesSummaryStatistics from './SalesSummaryStatistics';
import SalesMarketTable from './SalesMarketTable';
import { flatCategories, houseCategories } from 'utils/constants';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import RentsTable from '../RentalMarketReport/RentalMarketTables/RentsTable';
import RentsPaidChartWrapper from '../RentalMarketReport/RentalMarketTables/RentsPaidChartWrapper';
import DepthOfMarketTable from 'jsx/components/RentalMarketReport/RentalMarketTables/DepthOfMarketTable';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const SalesMarket = ({ property_type, number_of_bedrooms, area_code, onChartImagesChange }) => {
  const areaQuery = {
    area_code: area_code,
    limit: 10,
    offset: 0,
  };

  const {
    data: salesByArea = [],
    isLoading: salesByAreaDataLoading,
    isSuccess: salesByAreaDataLoaded,
  } = useQuery(['benchmarkAreaValuation', areaQuery], ({ signal }) =>
    apiSearchBenchmarkAreaValuation(areaQuery, signal),
  );

  const combinedData = useMemo(() => {
    if (salesByAreaDataLoaded) {
      const areaFormattedData = formatSalesData(salesByArea);
      const resultData = combineRentsData({}, areaFormattedData);
      return resultData;
    }
    return { Flat: {}, House: {} };
  }, [salesByArea, salesByAreaDataLoaded]);

  const [chartImages, setChartImages] = useState({
    salesDepthOfMarketChartImg: null,
    salesChartLowerQuartileImg: null,
    salesChartMedianQuartileImg: null,
    salesChartUpperQuartileImg: null,
  });

  const debouncedChartImages = useRef(null);

  useEffect(() => {
    clearTimeout(debouncedChartImages.current);
    debouncedChartImages.current = setTimeout(() => {
      onChartImagesChange && onChartImagesChange(chartImages);
    }, 300);

    return () => clearTimeout(debouncedChartImages.current);
  }, [chartImages, onChartImagesChange]);

  const updateSalesChartImage = useCallback((quartile, img) => {
    const keyMap = {
      'Lower Quartile': 'salesChartLowerQuartileImg',
      'Median Quartile': 'salesChartMedianQuartileImg',
      'Upper Quartile': 'salesChartUpperQuartileImg',
    };

    setChartImages((prev) => {
      if (prev[keyMap[quartile]] !== img) {
        return { ...prev, [keyMap[quartile]]: img };
      }
      return prev;
    });
  }, []);

  const updateChartImage = useCallback((key, img) => {
    setChartImages((prev) => {
      if (prev[key] !== img) {
        return { ...prev, [key]: img };
      }
      return prev;
    });
  }, []);

  return (
    <>
      <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
        <div className="fs-20 text-black"> Sales Market Report </div>
        <div className="flex items-center justify-center pr-4">
          <div className="inline-flex items-center ml-4">Area ({area_code})</div>
        </div>
      </div>

      <div className="card p-4">
        <div className="row w-100">
          <div className="col-12 col-lg-8 mb-8">
            {salesByAreaDataLoading ? (
              <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
            ) : (
              <div className=" overflow-x-auto w-100">
                <div className="flex" style={{ minWidth: '600px' }}>
                  <SalesMarketTable data={combinedData?.Flat} type="Flats" categories={flatCategories} />
                  <SalesMarketTable data={combinedData?.House} type="Houses" categories={houseCategories} />
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-4 ">
            {combinedData && property_type ? (
              <SalesSummaryStatistics
                property_type={property_type}
                number_of_bedrooms={number_of_bedrooms}
                combinedData={combinedData}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      {combinedData && (
        <>
          <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
            <div className="fs-20 text-black">Price Ranges </div>
            <div className="flex items-center justify-center pr-4">
              <div className="inline-flex items-center ml-4">Area ({area_code})</div>
            </div>
          </div>
          <div className="card p-2">
            <div className="w-100">
              {salesByAreaDataLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <div>
                  <div className="mb-10">
                    <div className="flex flex-col lg:flex-row overflow-x-auto w-100">
                      <div>
                        <RentsTable data={combinedData?.Flat} type="Flats" categories={flatCategories} />
                      </div>
                      <div>
                        <RentsTable data={combinedData?.House} type="Houses" categories={houseCategories} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row w-full px-4">
                    <RentsPaidChartWrapper
                      combinedData={combinedData}
                      district_code={''}
                      area_code={area_code}
                      renderImage={(img, quartile) => updateSalesChartImage(quartile, img)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
        <div className="fs-20 text-black">Depth of the market</div>
        <div className="flex items-center justify-center pr-4">
          <div className="inline-flex items-center ml-4">Area ({area_code})</div>
        </div>
      </div>

      <div className="card p-2">
        <div className="flex w-100">
          {salesByAreaDataLoading ? (
            <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
          ) : (
            <DepthOfMarketTable
              property_type={property_type}
              number_of_bedrooms={number_of_bedrooms}
              district_code={''}
              area_code={area_code}
              district={undefined}
              area={salesByArea}
              salesData={true}
              renderImage={(img) => updateChartImage('salesDepthOfMarketChartImg', img)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SalesMarket;
