import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  flexContent: {
    flex: 1,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 5,
  },
  tableHeader: {
    backgroundColor: '#EDF2F6',
    fontWeight: 'bold',
    height: '28px',
  },
  tableText: {
    textAlign: 'center',
  },
  districtText: {
    color: '#3571E5',
  },
  areaText: {
    color: '#3571e5',
  },
});

const SalesMarketTablePdf = ({ data, type, categories }) => {
  const metrics = [
    { key: 'avgPricePaid12m', label: 'Average price paid in last 12 months' },
    { key: 'avgPriceTop25', label: 'Average price of Top 25% of lets in last 12 months' },
    { key: 'avgPriceTop10', label: 'Average price of Top 10% of lets in last 12 months' },
  ];

  return (
    <View style={styles.flexContent}>
      <View style={styles.header}>
        <Text>{type}</Text>
      </View>

      <View style={styles.table}>
        <View style={[styles.tableRow, styles.tableHeader]}>
          {categories.map((category, colIndex) => (
            <Text key={colIndex} style={styles.tableCol}>
              {type === 'Flats'
                ? category !== '3 Bed'
                  ? category
                  : '3 + Bed'
                : category !== '4 Bed'
                  ? category
                  : '4 + Bed'}
            </Text>
          ))}
        </View>
        {metrics.map((metric, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={[styles.tableCol, { flex: categories.length + 1 }]}>{metric.label}</Text>
            </View>

            <View style={styles.tableRow}>
              {categories.map((category, colIndex) => (
                <Text key={colIndex} style={[styles.tableCol, styles.areaText]}>
                  {numeral(data?.[category]?.[metric.key]?.area).format() || '-'}
                </Text>
              ))}
            </View>
          </React.Fragment>
        ))}
      </View>
    </View>
  );
};

export default SalesMarketTablePdf;
