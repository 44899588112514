import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';

import PriceChart from 'jsx/components/Property/Components/PriceChart';
import RentalCharts from 'jsx/components/Property/Components/RentalCharts';

import { useEffect, useState } from 'react';
import { searchSuperIndicesData } from 'api/superindexes';
import { getTargetTime } from 'api/propertyIndex';
import TablesByDistrictData from 'jsx/components/Property/Components/TablesByDistrictData';

const PropertyPrice = ({ setPriceChartImg, setRentPriceChartImg, setRentYieldChartImg }) => {
  const { propertyId } = useParams();

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [benchmarkDropdown, setBenchmarkDropDown] = useState([]);
  const [searchTableData, setSearchTableData] = useState([]);

  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));

  const { data: property, refetch: refetchProperty } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  const getBenchmarkData = async (propertyIndexData) => {
    try {
      const searchResponse = await searchSuperIndicesData({
        offset: 0,
        limit: 50,
        district_exact: propertyIndexData.district_code,
        num_of_bedrooms: [1, 2, 3, 4, 5],
        order_by: 'Chg_5Y',
        ascending: false,
      });

      const searchData = searchResponse?.data?.data;
      setSearchTableData(searchData);

      const dropdownData = searchData.map((searchResult) => ({
        label: `${searchResult.post_town},
                District: ${searchResult.district},
                Type ${searchResult.property_type},
                Rooms: ${searchResult.number_of_bedrooms}`,
        value: searchResult.super_index_name,
        id: searchResult.index,
        ...searchResult,
      }));

      const identifier = `${propertyIndexData.post_town} ${propertyIndexData.district_code} ${propertyIndexData.property_type} ${propertyIndexData.number_of_bedrooms}`;
      const selectedOption = dropdownData.find((item) => item.value === identifier);
      setSelectedIndex(selectedOption);
      dropdownData.sort((a, b) => a.label.localeCompare(b.label));
      setBenchmarkDropDown(dropdownData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property?.property_index_data && property.status !== 'In progress') {
      getBenchmarkData(property?.property_index_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  if (!property) {
    return <></>;
  }

  return (
    <>
      <PriceChart
        property={property}
        benchmarkDropdown={benchmarkDropdown}
        selectedBenchmark={selectedIndex}
        updateBenchmarkSelection={(selectedBenchmark) => setSelectedIndex(selectedBenchmark)}
        targetTime={targetTime}
        renderImage={setPriceChartImg}
      />

      <RentalCharts
        property={property}
        selectedBenchmark={selectedIndex}
        targetTime={targetTime}
        updateProperty={() => refetchProperty()}
        setRentPriceChartImg={setRentPriceChartImg}
        setRentYieldChartImg={setRentYieldChartImg}
      />

      <TablesByDistrictData property={property} searchTableData={searchTableData} />
    </>
  );
};

export default PropertyPrice;
