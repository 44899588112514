import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import ListingTable from '../../Shared/ListingTable';
import MapView from '../../Shared/MapView';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getListingsCSV, getPropertyListings } from '../../../../api/listings';
import moment from 'moment';
import _ from 'lodash';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { createProperty } from '../../../../api/property';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import PropertyGenerationStatusModal from '../../Dashboard/Components/PropertyGenerationStatusModal';
import { ReactComponent as InfoBlue } from 'icons/custom/info-icon-blue.svg';
import { propertySVGs } from '../../../../utils/helpers';
import { getPropertyIndicesbyUPRN } from '../../../../api/propertyIndex';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { ReactComponent as DownloadSvg } from 'icons/custom/download.svg';
import axios from 'axios';
import { formatAddress } from 'utils/address';

const Listings = ({ dropdownOptions, selectedIndex }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [listingsData, setListingsData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [controlledSelectedRows, setControlledSelectedRows] = useState();
  const [selectedListings, setSelectedListings] = useState([]);
  const [valuationInProgress, setValuationInProgress] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const [filterData, setFilterData] = useState({
    offset: 0,
    limit: 10,
    order_by: 'address',
    ascending: false,
  });

  const queryClient = useQueryClient();

  const {
    data: listingsResult,
    isLoading,
    isSuccess: listingsLoaded,
  } = useQuery(['listings', filterData, selectedListings], ({ signal }) =>
    getPropertyListings(
      {
        ...filterData,
        min_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      },
      signal,
    ),
  );

  // eslint-disable-next-line no-unused-vars
  const listingsDataMemoized = useMemo(() => {
    if (listingsLoaded) {
      setListingsData(listingsResult?.data);
      setPageCount(Math.ceil(listingsResult?.total_count / 10));
      const selectedRowsList = {};
      listingsResult?.data.forEach((item, index) => {
        if (selectedListings.find((listing) => listing?.uprn === item.uprn)) {
          selectedRowsList[index] = true;
        }
      });
      setControlledSelectedRows(selectedRowsList);
      return listingsResult || {};
    } else {
      return {};
    }
  }, [listingsLoaded, listingsResult, selectedListings]);

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.ascending = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }

      setPageIndex(pageIndex);
      setFilterData(formData);
    },
    [filterData],
  );

  const onSelectedRowsChange = useCallback(
    ({ selectedRowIds }) => {
      let indexArrayInt = Object.keys(selectedRowIds)
        .filter((key) => selectedRowIds[key])
        .map((key) => parseInt(key));

      const selectedProperties = listingsData
        .filter((_, index) => indexArrayInt.includes(index))
        .map((listing) => listing);

      const excludedPropertyUprns = listingsData
        .filter((_, index) => !indexArrayInt.includes(index))
        .map((listing) => listing.uprn);

      const uniqueValues = _.unionBy(selectedListings, selectedProperties, 'uprn');
      const resultArray = uniqueValues.filter((item) => !excludedPropertyUprns.includes(item.uprn));

      setSelectedListings(resultArray);
    },
    [listingsData, selectedListings],
  );

  const toggleProperty = useCallback(
    (propertyId) => {
      const selectedProperties = [...selectedListings];
      const index = selectedProperties.findIndex((property) => property.property_id === propertyId);

      if (index !== -1) {
        selectedProperties.splice(index, 1);
      } else {
        const currentProperty = listingsData.find((property) => property.property_id === propertyId);
        selectedProperties.push(currentProperty);
      }
      setSelectedListings(selectedProperties);
    },
    [listingsData, selectedListings],
  );

  const generateValuation = async () => {
    setValuationInProgress(true);
    try {
      const updatedListings = [];

      for (const property of selectedListings) {
        const indicesResponse = await getPropertyIndicesbyUPRN(property.uprn);
        const forecastExists = indicesResponse?.data?.data[0]?.forecast !== undefined;

        const updatedProperty = {
          address: formatAddress(property.address),
          district_code: property.district_code,
          latitude: property.latitude,
          longitude: property.longitude,
          num_rooms: property.number_of_bedrooms,
          post_town: property.post_town,
          property_type: property.property_type,
          epc: property.current_energy_rating_epc,
          uprn: property.uprn.toString(),
          custom: !forecastExists,
        };
        updatedListings.push(updatedProperty);
      }

      const promises = updatedListings.map((property) => createProperty(property));
      const results = await Promise.all(promises);
      if (results) {
        queryClient.invalidateQueries(['dashboardData']);
        queryClient.invalidateQueries(['rawProperties']);
        queryClient.invalidateQueries(['rawProperties', 'in-progress']);
        setShowStatusModal(true);
      }
      setValuationInProgress(false);
    } catch (error) {
      setValuationInProgress(false);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  function pickSelectedProperties(selection) {
    const { post_town, district } = selection;
    return {
      post_town,
      district,
      property_type: [selection.property_type],
      number_of_bedrooms: [selection.number_of_bedrooms],
    };
  }

  const onSelectedOptionChange = (selectedOption) => {
    const formattedData = pickSelectedProperties(selectedOption);
    let formData = { ...filterData, ...formattedData };
    setFilterData(formData);
    setSelectedOption({ ...formData, ...selectedOption });
  };

  let cancelToken;
  const downloadCSV = async () => {
    setCsvLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      await getListingsCSV(
        {},
        {
          ...filterData,
          order_by: 'date',
          ascending: false,
          offset: 0,
          limit: 100,
          min_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        },
        { cancelToken: cancelToken.token },
      );

      setCsvLoading(false);
    } catch (error) {
      setCsvLoading(false);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  useEffect(() => {
    if (selectedIndex?.value) {
      onSelectedOptionChange(selectedIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <PropertyGenerationStatusModal
        setShowModal={setShowStatusModal}
        showModal={showStatusModal}
        selectedProperties={selectedListings.length}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="pb-0 m-3 mb-0">
              <h4 className="fs-20 text-black p-2 mb-2"> Listings </h4>

              <div className="flex justify-between items-center px-2">
                <div className="flex justify-start items-center px-2">
                  <h4 className="fs-16 mb-0 mr-3 my-0 py-0 text-sm"> Select District: </h4>
                  <Select
                    className="w-96"
                    key="lefthdropdown"
                    value={selectedOption}
                    onChange={onSelectedOptionChange}
                    options={dropdownOptions}
                    isSearchable={true}
                  />

                  {isLoading && <LoaderSvg className="ml-4 animate-spin h-8 w-8 text-white" />}
                </div>

                {selectedOption?.number_of_bedrooms ? (
                  <div className="flex items-center">
                    <InfoBlue className="w-4 mr-2" />
                    <span className="text-xs">
                      {selectedOption.number_of_bedrooms}-bedroom{' '}
                      {propertySVGs[selectedOption.property_type]?.name?.toLowerCase()}
                      {selectedOption.property_type === 'F' ? 's' : ' houses '} listed in {selectedOption?.district}{' '}
                      within the last 3 months
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="px-4 pb-4">
              <MapView
                properties={listingsData}
                filteredIds={[]}
                selectedProperties={selectedListings}
                toggleProperty={toggleProperty}
                loading={isLoading}
                listingMode={true}
              />

              <ListingTable
                listings={listingsData}
                onSelectedRowsChange={onSelectedRowsChange}
                controlledSelectedRows={controlledSelectedRows}
                pageCount={pageCount}
                pageIndex={pageIndex}
                fetchData={fetchData}
                loading={isLoading}
              />

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="mr-2">
                    <InfoAsterisk
                      text={
                        <div className="text-gray-500 py-1">
                          Downloaded CSV file will include the most recent 100 listings
                        </div>
                      }
                      color="text-white"
                      placement="top"
                      icon={<InfoBlue className="w-4" />}
                    />
                  </div>

                  <SecondaryButton
                    className="w-36 mr-3"
                    onClick={() => downloadCSV()}
                    disabled={csvLoading || !(listingsData.length > 0)}
                  >
                    <DownloadSvg className="mr-1.5" />
                    Download CSV
                  </SecondaryButton>
                  {csvLoading && <LoaderSvg className="animate-spin h-8 w-8 text-white mr-2" />}
                </div>

                <div className="flex items-center">
                  {valuationInProgress && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
                  <PrimaryButton
                    className={'mr-2 ml-2 px-3 w-auto'}
                    onClick={() => generateValuation()}
                    disabled={valuationInProgress || !(selectedListings.length > 0)}
                  >
                    Generate valuation
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listings;
