import React, { useCallback, useEffect, useState } from 'react';
import PropertiesListFilter from './PropertiesListFilter';
import { getPaginatedUserProperties } from '../../../../api/property';
import debounce from 'lodash.debounce';
import axios from 'axios';
import PropertiesListTable from './PropertiesListTable';

const FilteredProperties = ({ portfolio, onSelectedPropertiesChange, appraisal }) => {
  const [paginatedPropertiesList, setPaginatedPropertiesList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [controlledSelectedRows, setControlledSelectedRows] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
  const [filterData, setFilterData] = useState({
    address: '',
    rooms: [],
    property_types: [],
    order_by: 'created_at',
    ascending: false,
    limit: 10,
    offset: 0,
    status: ['Completed', 'Comparables In progress'],
    has_appraisal: appraisal ? true : undefined,
  });

  const getPropertiesAPI = useCallback(async (filter) => {
    setLoading(true);
    try {
      const searchResponse = await getPaginatedUserProperties(filter);
      setLoading(false);
      return searchResponse;
    } catch (error) {
      setLoading(false);
    }
  }, []);

  let cancelToken;
  const getListingData = async (filter) => {
    setLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel('Operation cancelled due to new Request');
      }
      cancelToken = axios.CancelToken.source();
      const searchResponse = await getPropertiesAPI(filter);

      if (searchResponse && searchResponse?.data) {
        setPaginatedPropertiesList(searchResponse?.data);
        setPageCount(Math.ceil(searchResponse?.total_count / 10));
        const selectedRowsList = {};

        searchResponse?.data.forEach((item, index) => {
          if (selectedPropertyIds && selectedPropertyIds.find((propertyId) => propertyId === item.id)) {
            selectedRowsList[index] = true;
          }
        });

        setControlledSelectedRows(selectedRowsList);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => getListingData(params), 200),
    [selectedPropertyIds],
  );

  const updateFilter = (filter) => {
    let formData = { ...filterData, ...filter };

    let resultFilter = {
      ...filterData,
      address: formData.address ? formData.address : '',
      rooms: formData.number_of_bedrooms ? [formData.number_of_bedrooms, formData.number_of_bedrooms + '.0'] : [],
      property_types: formData.property_type ? [formData.property_type] : [],
      limit: 10,
      offset: 0,
    };
    setFilterData(resultFilter);
    setPageIndex(0);
  };

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.ascending = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }

      setPageIndex(pageIndex);
      setFilterData(formData);
    },
    [filterData],
  );

  const onSelectedRowsChange = useCallback(
    ({ selectedRowIds }) => {
      let indexArrayInt = Object.keys(selectedRowIds)
        .filter((key) => selectedRowIds[key])
        .map((key) => parseInt(key));

      const selectedProperties = paginatedPropertiesList
        .filter((_, index) => indexArrayInt.includes(index))
        .map((property) => property.id);

      const excludedPropertyIds = paginatedPropertiesList
        .filter((_, index) => !indexArrayInt.includes(index))
        .map((property) => property.id);

      const uniqueValues = selectedPropertyIds
        ? [...new Set([...selectedProperties, ...selectedPropertyIds])]
        : selectedProperties;
      const resultArray = uniqueValues.filter((item) => !excludedPropertyIds.includes(item));
      setSelectedPropertyIds(resultArray);
    },
    [paginatedPropertiesList, selectedPropertyIds],
  );

  useEffect(() => {
    debouncedChangeHandler(filterData);
  }, [filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSelectedPropertiesChange(selectedPropertyIds);
  }, [selectedPropertyIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedPropertyIds(portfolio.properties);
    setFilterData({
      address: '',
      rooms: [],
      property_types: [],
      order_by: 'created_at',
      ascending: false,
      limit: 10,
      offset: 0,
      has_appraisal: appraisal ? true : undefined,
    });
  }, [appraisal, portfolio]);

  return (
    <div>
      <PropertiesListFilter updateFilter={(params) => updateFilter(params)} loading={loading}></PropertiesListFilter>
      <PropertiesListTable
        properties={paginatedPropertiesList}
        onSelectedRowsChange={onSelectedRowsChange}
        controlledSelectedRows={controlledSelectedRows}
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </div>
  );
};
export default FilteredProperties;
