import React from 'react';
import { ReactComponent as FlatSvg } from 'icons/custom/flats.svg';
import { ReactComponent as HouseSvg } from 'icons/custom/houses.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const SalesMarketTable = ({ data, type, categories }) => {
  const metrics = [
    { key: 'avgPricePaid12m', label: 'Average price paid in last 12 months' },
    { key: 'avgPriceTop25', label: 'Average price of Top 25% of lets in last 12 months' },
    { key: 'avgPriceTop10', label: 'Average price of Top 10% of lets in last 12 months' },
  ];

  return (
    <div>
      <h5 className="text-lg my-2 flex">
        {type === 'Flats' ? <FlatSvg className="h-5 mr-2" /> : <HouseSvg className="h-5 mr-2" />}
        {type}
      </h5>
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            {type === 'Flats' ? <td></td> : ''}
            {categories.map((category, colIndex) => (
              <td
                key={colIndex}
                className="text-left text-black font-medium p-2 text-md border border-gray-300"
                style={{ color: '#3571E5' }}
              >
                {type === 'Flats'
                  ? category !== '3 Bed'
                    ? category
                    : '3 + Bed'
                  : category !== '4 Bed'
                    ? category
                    : '4 + Bed'}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {metrics.map((metric, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr>
                <td
                  colSpan={categories.length + 1}
                  className="text-left text-black font-normal text-sm px-2 border border-gray-300"
                  style={{ backgroundColor: '#EDF2F6', height: '28px' }}
                >
                  {type === 'Flats' ? metric.label : ''}
                </td>
              </tr>
              <tr>
                {type === 'Flats' ? (
                  <td className="text-left text-black font-normal text-sm px-2 border border-gray-300">Area</td>
                ) : (
                  ''
                )}
                {categories.map((category, colIndex) => (
                  <td
                    key={colIndex}
                    className="text-left font-light py-2 pl-2 pr-8 text-md border border-gray-300"
                    style={{ color: '#3571E5' }}
                  >
                    {numeral(data?.[category]?.[metric.key]?.area).format() || '-'}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesMarketTable;
