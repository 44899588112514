import { Modal } from 'react-bootstrap';
import RentalMarket from '../Routes/RentalMarket';
import PropertyPrice from '../Routes/PropertyPrice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useQuery } from '@tanstack/react-query';
import { apiGetPremiumValue } from 'api/premiumvalue';
import {
  apiEmployementStatusPercentages,
  apiHouseholdPercentages,
  apiRentalMarketArea,
  apiRentalMarketDistrict,
  apiSearchBenchmarkAreaValuation,
  apiTenurePercentagesHousehold,
} from 'api/avm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { combineRentsData, formatRentsData, formatSalesData } from 'utils/helpers';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import PropertyPdf from '../../Property/PdfComponents/PropertyPdf';
import { getDemographicsByDistrictCode } from 'api/demographics';
import { getComparablesByIds, getPropertyComparables, getPropertyTransactions } from 'api/comparables';
import { getPropertyListings } from 'api/listings';
import { MILES_TO_KILOMETERS } from 'utils/constants';
import moment from 'moment';
import { searchSuperIndicesData } from 'api/superindexes';
import SalesMarket from '../Routes/SalesMarket';

const PropertyPdfModal = ({ showModal, setShowModal, property }) => {
  const { district_code, area_code, property_type, number_of_bedrooms } = property?.property_index_data || {};

  const [pdfReady, setPdfReady] = useState(false);
  const [priceChartImg, setPriceChartImg] = useState();
  const [rentPriceChartImg, setRentPriceChartImg] = useState();
  const [rentYieldChartImg, setRentYieldChartImg] = useState();
  const [demographicsData, setDemographicsData] = useState(null);
  const [searchTableData, setSearchTableData] = useState([]);
  const [delayedRender, setDelayedRender] = useState(false);

  const [finalChartImages, setFinalChartImages] = useState({});
  const [salesChartImages, setFinalSalesChartImages] = useState({});

  const [comparablesState, setComparablesState] = useState({
    minMultiplier: 0.9,
    maxMultiplier: 1.1,
    distance: 1,
    callCount: 0,
  });

  const linkRef = useRef(null);

  const fileName = useMemo(() => {
    if (!property?.address) return 'Property_Valuation.pdf';

    const formattedAddress = property.address.replace(/\s+/g, '_');
    return `Valuation_${formattedAddress}.pdf`;
  }, [property?.address]);

  const getSearchTableData = async (property) => {
    const searchResponse = await searchSuperIndicesData({
      offset: 0,
      limit: 50,
      district_exact: property.district_code,
      num_of_bedrooms: [1, 2, 3, 4, 5],
      order_by: 'Chg_5Y',
      ascending: false,
    });

    const searchData = searchResponse?.data?.data;
    setSearchTableData(searchData);
  };

  const updateComparablesState = (updates) => {
    setComparablesState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  useEffect(() => {
    if (property?.property_index_data && property.status !== 'In progress') {
      getSearchTableData(property);
      getDemographicsByProperty(property);
    }
  }, [property]);

  const getDemographicsByProperty = async (property) => {
    try {
      const demographicsData = await getDemographicsByDistrictCode(property?.property_index_data?.district_code);
      setDemographicsData({
        demographics: demographicsData,
        district_code: property?.property_index_data?.district_code,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const { data: premiumValue } = useQuery({
    queryKey: [
      'premiumValue',
      `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
    ],
    queryFn: ({ signal }) =>
      apiGetPremiumValue(
        `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
        signal,
      ),
    enabled: !!property?.property_index_data,
  });

  const districtQuery = {
    district: district_code,
    limit: 20,
    offset: 0,
  };
  const areaQuery = {
    area_code: area_code,
    limit: 20,
    offset: 0,
  };

  const {
    data: rentalMarketDistrict = [],

    isSuccess: rentalMarketDistrictDataLoaded,
  } = useQuery(['rentalMarketDistrict', districtQuery], ({ signal }) => apiRentalMarketDistrict(districtQuery, signal));

  const { data: rentalMarketArea = [], isSuccess: rentalMarketAreaDataLoaded } = useQuery(
    ['rentalMarketArea', areaQuery],
    ({ signal }) => apiRentalMarketArea(areaQuery, signal),
  );

  const combinedData = useMemo(() => {
    if (rentalMarketDistrictDataLoaded && rentalMarketAreaDataLoaded) {
      const districtFormattedData = formatRentsData(rentalMarketDistrict);
      const areaFormattedData = formatRentsData(rentalMarketArea);

      return combineRentsData(districtFormattedData, areaFormattedData);
    } else {
      return { Flat: {}, House: {} };
    }
  }, [rentalMarketDistrict, rentalMarketArea, rentalMarketDistrictDataLoaded, rentalMarketAreaDataLoaded]);

  const { data: employmentStatusData = [] } = useQuery(
    ['districtEmploymentData', { district: district_code }],
    ({ signal }) => apiEmployementStatusPercentages({ district: district_code }, signal),
  );

  const { data: comparablesData } = useQuery(
    ['comparables', property?.id, property?.status],
    ({ signal }) =>
      getPropertyComparables(
        property?.id,
        {
          max_price: property?.price ? Math.round(property?.price * 1.3) : 10000000,
          min_price: property?.price ? Math.round(property?.price * 0.7) : 0,
          max_size: null,
          null_toggle: true,
          offset: 0,
          old_new_lr: null,
          filtered_ids: [],
          bathrooms: [],
          current_energy_rating_epc: [],
          distance_from: null,
          freehold_leasehold: null,
          limit: 10,
          min_size: null,
          order_by: 'date',
          ascending: false,
        },
        signal,
      ),
    {
      enabled: !!property?.property_index_data && property?.status === 'Completed',
    },
  );

  const { employmentCategories, employmentDistrictData, employmentAreaData } = useMemo(() => {
    return employmentStatusData.reduce(
      (acc, item) => {
        if (!acc.uniqueEmploymentGroups.has(item.employment_group)) {
          acc.uniqueEmploymentGroups.add(item.employment_group);
          acc.employmentCategories.push(item.employment_group);
          acc.employmentDistrictData[item.employment_group] = (item.percentage_by_district || 0) / 100;
          acc.employmentAreaData[item.employment_group] = (item.percentage_by_area || 0) / 100;
        }
        return acc;
      },
      {
        uniqueEmploymentGroups: new Set(),
        employmentCategories: [],
        employmentDistrictData: {},
        employmentAreaData: {},
      },
    );
  }, [employmentStatusData]);

  const { data: householdData = [] } = useQuery(['districtHouseholdData', { district: district_code }], ({ signal }) =>
    apiHouseholdPercentages({ district: district_code }, signal),
  );

  const { householdCategories, householdDistrictData, householdAreaData } = useMemo(() => {
    return householdData.reduce(
      (acc, item) => {
        if (!acc.uniqueHouseholdGroups.has(item.household_group)) {
          acc.uniqueHouseholdGroups.add(item.household_group);
          acc.householdCategories.push(item.household_group);
          acc.householdDistrictData[item.household_group] = (item.percentage_by_district || 0) / 100;
          acc.householdAreaData[item.household_group] = (item.percentage_by_area || 0) / 100;
        }
        return acc;
      },
      {
        uniqueHouseholdGroups: new Set(),
        householdCategories: [],
        householdDistrictData: {},
        householdAreaData: {},
      },
    );
  }, [householdData]);

  const { data: tenureData = [] } = useQuery(['districtTenureData', { district: district_code }], ({ signal }) =>
    apiTenurePercentagesHousehold({ district: district_code }, signal),
  );

  const { tenureCategories, tenureDistrictData, tenureAreaData } = useMemo(() => {
    return tenureData.reduce(
      (acc, item) => {
        if (!acc.uniqueTenureGroups.has(item.tenure_group)) {
          acc.uniqueTenureGroups.add(item.tenure_group);
          acc.tenureCategories.push(item.tenure_group);
          acc.tenureDistrictData[item.tenure_group] = (item.percentage_by_district || 0) / 100;
          acc.tenureAreaData[item.tenure_group] = (item.percentage_by_area || 0) / 100;
        }
        return acc;
      },
      {
        uniqueTenureGroups: new Set(),
        tenureCategories: [],
        tenureDistrictData: {},
        tenureAreaData: {},
      },
    );
  }, [tenureData]);

  const { data: optimalComparablesData, isSuccess: optimalComparablesLoaded } = useQuery(
    [
      'comparables',
      property?.id,
      'optimal',
      property?.price,
      comparablesState.minMultiplier,
      comparablesState.maxMultiplier,
    ],
    ({ signal }) =>
      property?.comp_ids?.length > 0
        ? getComparablesByIds(property?.comp_ids, signal)
        : getPropertyComparables(
            property?.id,
            {
              order_by: 'distance_from',
              dataset: 'LR',
              ascending: true,
              distance_from: comparablesState.distance,
              max_price: property?.price ? Math.round(property?.price * comparablesState.maxMultiplier) : 10000000,
              min_price: property?.price ? Math.round(property?.price * comparablesState.minMultiplier) : 0,
              offset: 0,
              limit: 10,
            },
            signal,
          ),
    {
      enabled: !!property?.price && comparablesState.callCount < 10,
    },
  );

  useEffect(() => {
    if (property?.comp_ids?.length > 0 && optimalComparablesLoaded) {
      updateComparablesState({ callCount: 10 });
    } else if (optimalComparablesLoaded && comparablesState.callCount < 10) {
      const distinctComparables = optimalComparablesData?.data.filter(
        (v, i, a) => a.findIndex((t) => t.address === v.address) === i,
      );

      if (distinctComparables.length < 5) {
        updateComparablesState({
          minMultiplier: Math.max(0, comparablesState.minMultiplier - 0.1),
          maxMultiplier: comparablesState.maxMultiplier + 0.1,
          distance: comparablesState.distance + 1,
          callCount: comparablesState.callCount + 1,
        });
      }
    }
  }, [optimalComparablesLoaded, optimalComparablesData, comparablesState, property?.comp_ids?.length]);

  const optimalComparablesResult = useMemo(() => {
    if (optimalComparablesLoaded) {
      let comparables = optimalComparablesData?.data || [];

      if (!property?.comp_ids?.length) {
        comparables = comparables.filter((v, i, a) => a.findIndex((t) => t.address === v.address) === i);

        comparables.sort((a, b) => {
          const mainPropertyPrice = property?.price || 0;
          return Math.abs(a.price - mainPropertyPrice) - Math.abs(b.price - mainPropertyPrice);
        });
      }

      comparables = comparables.slice(0, 5);

      return comparables || {};
    } else {
      return {};
    }
  }, [optimalComparablesLoaded, optimalComparablesData?.data, property?.comp_ids?.length, property?.price]);

  const { data: listingsData } = useQuery(
    ['listings', property?.id],
    ({ signal }) =>
      getPropertyListings(
        {
          latitude: property?.latitude,
          longitude: property?.longitude,
          ascending: false,
          order_by: 'address',
          max_distance: 1 * MILES_TO_KILOMETERS,
          min_distance: 0,
          post_town: property?.property_index_data?.post_town,
          number_of_bedrooms: [parseInt(property?.property_index_data?.number_of_bedrooms)],
          property_type: [property?.property_index_data?.property_type],
          offset: 0,
          limit: 10,
          min_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        },
        signal,
      ),
    {
      enabled: !!property?.property_index_data && property?.status === 'Completed',
    },
  );

  const handleChartImagesChange = (updatedChartImages) => {
    setFinalChartImages(updatedChartImages);
  };

  const handleSalesChartImagesChange = (updatedChartImages) => {
    setFinalSalesChartImages(updatedChartImages);
  };

  const salesAreaQuery = {
    area_code: area_code,
    limit: 10,
    offset: 0,
  };

  const { data: salesByArea = [], isSuccess: salesByAreaDataLoaded } = useQuery(
    ['benchmarkAreaValuation', salesAreaQuery],
    ({ signal }) => apiSearchBenchmarkAreaValuation(salesAreaQuery, signal),
  );

  const salesCombinedData = useMemo(() => {
    if (salesByAreaDataLoaded) {
      const areaFormattedData = formatSalesData(salesByArea);
      const resultData = combineRentsData({}, areaFormattedData);
      return resultData;
    }
    return { Flat: {}, House: {} };
  }, [salesByArea, salesByAreaDataLoaded]);

  useEffect(() => {
    if (pdfReady && linkRef.current) {
      linkRef.current.click();
      setPdfReady(false);
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfReady]);

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => setDelayedRender(true), 300);
      return () => clearTimeout(timer);
    } else {
      setDelayedRender(false);
    }
  }, [showModal]);

  const { data: transactionsData, isSuccess: transactionsLoaded } = useQuery(
    ['property-transactions', property.id, 'soldPrice'],
    ({ signal }) =>
      getPropertyTransactions(
        { uprn: property.original_uprn ? property.original_uprn : property.uprn, type: 'soldPrice' },
        signal,
      ),
  );

  const { data: transactionsListingsData, isSuccess: listingsLoaded } = useQuery(
    ['property-transactions', property.id, 'askingPrice'],
    ({ signal }) =>
      getPropertyTransactions(
        { uprn: property.original_uprn ? property.original_uprn : property.uprn, type: 'askingPrice' },
        signal,
      ),
  );

  const formattedTransactions = useMemo(() => {
    if (transactionsLoaded && listingsLoaded) {
      const listingsWithType = transactionsListingsData.data.map((item) => ({
        ...item,
        type: 'listing',
        sortingDate: item.date_appeared,
      }));

      const transactionsWithType = transactionsData.data.map((item) => ({
        ...item,
        type: 'transaction',
        sortingDate: item.date_sold,
      }));

      const sortedData = [...listingsWithType, ...transactionsWithType].sort((a, b) => {
        return new Date(b.sortingDate) - new Date(a.sortingDate);
      });

      for (let i = sortedData.length - 1; i > 0; i--) {
        const currentTransaction = sortedData[i];
        const previousTransaction = sortedData[i - 1];

        if (currentTransaction.bedrooms !== previousTransaction.bedrooms) {
          previousTransaction.modifiedRooms = true;
        }
      }

      return sortedData;
    } else {
      return [];
    }
  }, [transactionsLoaded, listingsLoaded, transactionsListingsData, transactionsData]);

  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      centered
      enforceFocus={false}
    >
      <div>
        {delayedRender && (
          <PDFDownloadLink
            document={
              <PropertyPdf
                property={property}
                priceChartImg={priceChartImg}
                rentPriceChartImg={rentPriceChartImg}
                rentYieldChartImg={rentYieldChartImg}
                premiumValue={premiumValue}
                demographicsData={demographicsData}
                comparablesData={comparablesData}
                optimalComparablesData={optimalComparablesResult}
                listingsData={listingsData}
                searchTableData={searchTableData}
                combinedData={combinedData}
                district_code={district_code}
                property_type={property_type}
                number_of_bedrooms={number_of_bedrooms}
                area_code={area_code}
                employmentCategories={employmentCategories}
                employmentDistrictData={employmentDistrictData}
                employmentAreaData={employmentAreaData}
                householdCategories={householdCategories}
                householdDistrictData={householdDistrictData}
                householdAreaData={householdAreaData}
                tenureCategories={tenureCategories}
                tenureDistrictData={tenureDistrictData}
                tenureAreaData={tenureAreaData}
                rentalMarketDistrict={rentalMarketDistrict}
                rentalMarketArea={rentalMarketArea}
                chartImages={finalChartImages}
                transactions={formattedTransactions}
                salesChartImages={salesChartImages}
                salesByArea={salesByArea}
                salesCombinedData={salesCombinedData}
              />
            }
            fileName={fileName}
          >
            {({ url, loading }) => {
              if (!loading && url) {
                setPdfReady(true);
              }

              return (
                <div ref={linkRef} style={{ display: 'none' }}>
                  <button onClick={() => (window.location.href = url)}>Download </button>
                </div>
              );
            }}
          </PDFDownloadLink>
        )}
      </div>

      {showModal ? (
        <div className="flex items-center justify-center h-40">
          <LoaderSvg className="animate-spin h-10 w-10 text-primary" />
        </div>
      ) : null}

      {delayedRender && (
        <div style={{ width: 1200, opacity: 0, position: 'absolute' }}>
          <PropertyPrice
            setPriceChartImg={setPriceChartImg}
            setRentPriceChartImg={setRentPriceChartImg}
            setRentYieldChartImg={setRentYieldChartImg}
          ></PropertyPrice>
          <RentalMarket onChartImagesChange={handleChartImagesChange}></RentalMarket>
          <SalesMarket onChartImagesChange={handleSalesChartImagesChange}></SalesMarket>
        </div>
      )}
    </Modal>
  );
};

export default PropertyPdfModal;
