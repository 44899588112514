import BarChart from './BarChart';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const propertyTypeMapping = {
  F: 'Flat',
  T: 'House',
  S: 'House',
  D: 'House',
};

const DepthOfMarketTable = ({
  property_type,
  number_of_bedrooms,
  district_code,
  area_code,
  district,
  area,
  renderImage,
  salesData = false,
}) => {
  const mappedType = propertyTypeMapping[property_type] || property_type;

  let adjustedBedrooms = Number(number_of_bedrooms);

  if (mappedType === 'Flat' && number_of_bedrooms > 3) {
    adjustedBedrooms = 3;
  } else if (mappedType === 'House' && number_of_bedrooms > 4) {
    adjustedBedrooms = 4;
  } else if (mappedType === 'House' && number_of_bedrooms < 2) {
    adjustedBedrooms = 2;
  }

  if (!area) return '';

  const districtItem =
    district && district.find((item) => item.type === mappedType && item.number_of_bedrooms === adjustedBedrooms);
  const districtData = districtItem?.depth_of_market
    ? JSON.parse(districtItem.depth_of_market.replace(/'/g, '"'))
    : null;

  const areaItem = area.find((item) => item.type === mappedType && item.number_of_bedrooms === adjustedBedrooms);
  const areaData = areaItem?.depth_of_market ? JSON.parse(areaItem.depth_of_market.replace(/'/g, '"')) : null;

  const ranges = areaData && typeof areaData === 'object' ? Object.keys(areaData) : [];

  const formattedRanges = ranges.map((range, index) => {
    if (index === 0) {
      return salesData ? 'Under £50,000' : 'Under £500';
    } else if (index === ranges.length - 1) {
      return salesData ? '£3,000,000+ ' : `£3750+`;
    } else {
      const [start, end] = range.split('-');
      return `£${start}-£${end}`;
    }
  });

  return (
    <div className="row w-100">
      <div className="col-12 col-lg-6">
        <div className="flex p-4">
          <div>
            <table className="border-collapse ">
              <thead>
                <tr className="text-left">
                  <th className="font-medium p-2 text-md text-black">Range</th>
                  {districtData && <th className="font-medium p-2 text-md text-black border-l">District (%)</th>}
                  <th className="font-medium p-2 text-md text-black border-l">Area (%)</th>
                </tr>
              </thead>
              <tbody>
                {ranges.map((range, index) => (
                  <tr key={index}>
                    <td className="p-2">{formattedRanges[ranges.indexOf(range)]}</td>
                    {districtData && (
                      <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#3571E5' }}>
                        {(districtData[range] * 100).toFixed(0)}%
                      </td>
                    )}
                    <td
                      className="p-2 border-l border-gray-300 text-center"
                      style={{ color: districtData ? '#FF4560' : '#3571E5' }}
                    >
                      {(areaData[range] * 100).toFixed(0)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="p-4">
          {areaData ? (
            <BarChart
              categories={formattedRanges}
              districtData={districtData}
              areaData={areaData}
              district_code={district_code}
              area_code={area_code}
              renderImage={renderImage}
              orientation="horizontal"
            ></BarChart>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default DepthOfMarketTable;
