import React from 'react';
import { ReactComponent as FlatSvg } from 'icons/custom/flats.svg';
import { ReactComponent as HouseSvg } from 'icons/custom/houses.svg';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const RentsTable = ({ data, type, categories }) => {
  const metrics = [
    { key: 'lowerQuartile', label: 'Lower Quartile' },
    { key: 'medianQuartile', label: 'Median Quartile' },
    { key: 'upperQuartile', label: 'Upper Quartile' },
  ];

  return (
    <div className="m-4">
      <table className="border-collapse">
        <thead>
          <tr>
            <td className="text-left text-black font-normal p-2 text-sm border-b border-gray-300 flex">
              {type === 'Flats' ? <FlatSvg className="h-4 mr-1" /> : <HouseSvg className="h-4 mr-1" />}
              {type}
            </td>
            {categories.map((category, colIndex) => (
              <td
                key={colIndex}
                className="text-left text-black font-medium p-2 text-md border-b border-l border-gray-300"
                style={{ color: '#3571E5' }}
              >
                {type === 'Flats'
                  ? category !== '3 Bed'
                    ? category
                    : '3+ Bed'
                  : category !== '4 Bed'
                    ? category
                    : '4+ Bed'}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {metrics.map((metric, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr>
                <td className="text-left font-light py-2 pl-2 pr-8 border-gray-300 text-sm">{metric.label}</td>
                {categories.map((category, colIndex) => (
                  <td key={colIndex} className="text-left font-light py-2 pl-2 pr-8 text-md border-l border-gray-300">
                    {data?.[category]?.[metric.key]?.district && (
                      <div style={{ color: '#3571E5' }}>
                        {numeral(data?.[category]?.[metric.key]?.district).format() || '-'}
                      </div>
                    )}
                    <div style={{ color: data?.[category]?.[metric.key]?.district ? '#FF4560' : '#3571E5' }}>
                      {numeral(data?.[category]?.[metric.key]?.area).format() || '-'}
                    </div>
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RentsTable;
