import React, { useEffect, useState } from 'react';
import ItemVsIndexChart from '../../Dashboard/Charts/ItemVsIndexChart';
import Select from 'react-select';
import { searchCityIndices } from '../../../../api/cityIndex';
import { seriesObjectToArray } from '../../../../utils/helpers';
import moment from 'moment';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { formatAddress } from 'utils/address';

const PriceChart = ({
  property,
  benchmarkDropdown,
  selectedBenchmark,
  updateBenchmarkSelection,
  targetTime,
  chartRef,
  renderImage,
  extendedData,
}) => {
  const address = `${property?.address},
                    District: ${property?.property_index_data?.district_code},
                    Type: ${property?.property_index_data?.property_type},
                    Rooms: ${property?.property_index_data?.number_of_bedrooms}`;

  const propertyGraphSeriesData =
    seriesObjectToArray(property?.property_index_data?.forecast, targetTime, extendedData) || [];
  const [selectedCityIndex, setSelectedCityIndex] = useState(null);
  const [benchmarkGraphSeriesData, setBenchmarkGraphSeriesData] = useState([]);
  const [cityIndexGraphSeriesData, setCityIndexGraphSeriesData] = useState([]);
  const [cityIndexDropdown, setCityIndexDropdown] = useState([]);
  const [lastTransactionData, setLastTransactionData] = useState(null);

  const handleChange = function (selectedOption) {
    updateBenchmarkSelection(selectedOption);
  };

  const handleCityIndexChange = function (selectedOption) {
    setSelectedCityIndex(selectedOption);
    setCityIndexGraphSeriesData(seriesObjectToArray(selectedOption?.forecast || {}, targetTime, extendedData));
  };

  const getCityIndexBenchmark = async (data) => {
    try {
      const searchResponse = await searchCityIndices({
        offset: 0,
        limit: 24,
        post_town: data.post_town,
      });

      const nationwideIndexResponse = await searchCityIndices({
        offset: 0,
        limit: 1,
        index_exact: 'UK',
      });

      const searchData = searchResponse?.data?.data || [];
      let dropdownData = searchData.map((searchResult, index) => ({
        label:
          `${searchResult.post_town}` +
          (searchResult?.property_type && searchResult.property_type !== 'nan'
            ? `, Type ${searchResult.property_type}`
            : '') +
          (searchResult?.number_of_bedrooms && searchResult.number_of_bedrooms !== 'NaN'
            ? `, Rooms: ${searchResult.number_of_bedrooms}`
            : ''),
        value: searchResult.index_name,
        id: index,
        forecast: searchResult.forecast,
      }));

      const nationwideIndex = nationwideIndexResponse?.data?.data[0];

      nationwideIndex &&
        dropdownData.unshift({
          label: `UK`,
          value: nationwideIndex.index_name,
          id: dropdownData.length,
          forecast: nationwideIndex.forecast,
        });

      const identifier = `${data.post_town} ${data.property_type} ${data.number_of_bedrooms}`;
      const selectedOption = dropdownData.find((item) => item.value === identifier);

      dropdownData.sort((a, b) => a.label.localeCompare(b.label));
      setCityIndexDropdown(dropdownData);
      handleCityIndexChange(selectedOption);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setBenchmarkGraphSeriesData(seriesObjectToArray(selectedBenchmark?.forecast, targetTime, extendedData));
  }, [selectedBenchmark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (property?.property_index_data) {
      getCityIndexBenchmark(property?.property_index_data);

      const startDate = moment().subtract(60, 'months');
      const endDate = moment().add(24, 'months');
      const formattedDate = moment(
        property?.property_index_data?.last_transaction_date,
        ['YYYY-MM-DD', 'MM/DD/YYYY'],
        true,
      );

      if (moment(formattedDate).isBetween(startDate, endDate)) {
        setLastTransactionData({
          date: property?.property_index_data?.last_transaction_date,
          price: property?.property_index_data?.last_transaction_price,
        });
      }
    }
  }, [property, benchmarkDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Property price chart </h4>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0 truncate">{formatAddress(address)}</h4>
              <h4 className="fs-16 mb-0 mx-1 my-0 py-0"> vs </h4>
              <Select
                className="w-1/3 mx-2"
                value={selectedBenchmark}
                onChange={handleChange}
                options={benchmarkDropdown}
                isSearchable={true}
              />
              <Select
                className="w-1/3 mx-2"
                value={selectedCityIndex}
                onChange={handleCityIndexChange}
                options={cityIndexDropdown}
                isSearchable={true}
              />
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              {propertyGraphSeriesData?.length ? (
                <div id="BarCharts2" className="bar-chart">
                  <ItemVsIndexChart
                    address={address}
                    index={selectedBenchmark?.label}
                    index2={selectedCityIndex?.label}
                    itemGraphSeriesData={propertyGraphSeriesData}
                    benchmarkGraphSeriesData={benchmarkGraphSeriesData}
                    benchmark2GraphSeriesData={cityIndexGraphSeriesData}
                    secondaryAxisButtonEnabled={true}
                    lastTransactionData={lastTransactionData}
                    chartRef={chartRef}
                    renderImage={renderImage}
                    extendedData={extendedData}
                  />
                </div>
              ) : (
                <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                  <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceChart;
