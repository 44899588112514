import { useQuery } from '@tanstack/react-query';
import { apiGetPropertyAnalytics } from 'api/property';
import { useParams } from 'react-router-dom';
import queryClient from 'utils/queryClient';
import PropertyDetails from 'jsx/components/Property/Components/PropertyDetails';
import PropertyVisuals from 'jsx/components/Property/Components/PropertyVisuals';

const PropertyDetailsSection = () => {
  const { propertyId } = useParams();

  const { data: property } = useQuery(
    ['property-analytics', propertyId],
    ({ signal }) => apiGetPropertyAnalytics(propertyId, signal),
    {
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
          queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
          return false;
        }
        return 30000;
      },
      onSuccess: () => {
        if (property?.status === 'Completed' && property?.property_index_data) {
          queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        }
      },
    },
  );

  if (!property) {
    return <></>;
  }

  return (
    <>
      <PropertyDetails property={property} demographicsData={{}} />
      {(property?.property_images?.length || property?.floor_plans?.length) && <PropertyVisuals property={property} />}
    </>
  );
};

export default PropertyDetailsSection;
