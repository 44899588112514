import { Text, View, StyleSheet } from '@react-pdf/renderer';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    marginBottom: 10,
    fontSize: 8,
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    flex: 1,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    fontSize: 8,
    padding: 5,
  },
  tableColLabel: {
    flex: 2,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    fontSize: 8,
    padding: 5,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    fontSize: 10,
  },
  tableText: {
    textAlign: 'center',
  },
  categoryText: {
    fontWeight: 'bold',
    fontSize: 8,
    color: '#3571E5',
  },
  metricText: {
    fontWeight: 'normal',
    fontSize: 8,
    color: '#FF4560',
  },
});

const RentsTablePdf = ({ data, type, categories, salesData = false }) => {
  const metrics = [
    { key: 'lowerQuartile', label: 'Lower Quartile' },
    { key: 'medianQuartile', label: 'Median Quartile' },
    { key: 'upperQuartile', label: 'Upper Quartile' },
  ];

  return (
    <View>
      <View style={styles.header}>
        <Text>{type}</Text>
      </View>

      <View style={styles.table}>
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={styles.tableColLabel}></Text>
          {categories.map((category, index) => (
            <Text key={index} style={styles.tableCol}>
              {type === 'Flats'
                ? category !== '3 Bed'
                  ? category
                  : '3+ Bed'
                : category !== '4 Bed'
                  ? category
                  : '4+ Bed'}
            </Text>
          ))}
        </View>

        {metrics.map((metric, rowIndex) => (
          <View style={styles.tableRow} key={rowIndex}>
            <Text style={styles.tableColLabel}>{metric.label}</Text>
            {categories.map((category, colIndex) => (
              <View key={colIndex} style={styles.tableCol}>
                {data?.[category]?.[metric.key]?.district ? (
                  <Text style={styles.categoryText}>
                    {numeral(data?.[category]?.[metric.key]?.district).format() || '-'}
                  </Text>
                ) : null}
                <Text style={salesData ? styles.categoryText : styles.metricText}>
                  {numeral(data?.[category]?.[metric.key]?.area).format() || '-'}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

export default RentsTablePdf;
